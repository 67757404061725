export const CREATE_DOCTOR_REQUEST="CREATE_DOCTOR_REQUEST";
export const CREATE_DOCTOR_SUCCESS="CREATE_DOCTOR_SUCCESS";
export const CREATE_DOCTOR_FAIL="CREATE_DOCTOR_FAIL";

export const FETCH_DOCTOR_REQUEST="FETCH_DOCTOR_REQUEST";
export const FETCH_DOCTOR_SUCCESS="FETCH_DOCTOR_SUCCESS";
export const FETCH_DOCTOR_FAIL="FETCH_DOCTOR_FAIL";

export const UPDATE_DOCTOR_REQUEST="UPDATE_DOCTOR_REQUEST";
export const UPDATE_DOCTOR_SUCCESS="UPDATE_DOCTOR_SUCCESS";
export const UPDATE_DOCTOR_FAIL="UPDATE_DOCTOR_FAIL";


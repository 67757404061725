import React, { useEffect } from "react";
import { FaPen, FaTrash, FaUser } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserAction,
  getUserAction,
} from "../../Actions/UserAction/UserAction";
import { useNavigate } from "react-router-dom";

const ShowUser = () => {
  const dispatch = useDispatch();
  const getUsers = useSelector((state) => state.getUsers);
  const { users, loading } = getUsers;
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getUserAction());
  }, []);
  return (
    <div className="bg-white lg:w-[95%] mt-2  m-auto rounded-sm p-2">
      <div className="flex justify-between">
        <div className="flex items-center gap-2">
          <p className="text-teal-500">
            <FaUser />
          </p>
          <p>User</p>
        </div>
        <div>
          <button className="btn-info" onClick={() => navigate("/user/create")}>
            Add User
          </button>
        </div>
      </div>
      <div className="parent-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Full Name</th>
              <th>Email</th>
              {/* <th>Phone</th> */}
              <th>Admin Type</th>

              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  {/* <td>{user.phone}</td> */}
                  <td>{user.admin_type == 1 ? "Admin" : "Staff"}</td>
                  <td>
                    <div className="flex gap-2">
                      <button
                        className="btn-danger py-2"
                        onClick={() => {
                          dispatch(deleteUserAction(user.id));
                        }}
                      >
                        <FaTrash />
                      </button>
                      <button className="btn-primary py-2" onClick={()=>navigate('/user/show/'+user.id)}>
                        <FaPen />
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {/* <userModal isOpen={isModalOpen} onClose={closeModal} /> */}
    </div>
  );
};

export default ShowUser;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { getOccupationAction } from "../../Actions/OccupationAction/OccupationAction";
import { getDoctorAction } from "../../Actions/DoctorAction/DoctorAction";
import { FaPlus, FaTrash } from "react-icons/fa6";
import { getAge } from "../../Actions/getAge";
import { IoIosCloudUpload } from "react-icons/io";
import { createPatientAction } from "../../Actions/PatientActions/PatientAction";

const PatientScreen = () => {
  const initialState = {
    fullname: "",
    phone: "",
    birthDate: "",
    gender: "Male",
    martialStatus: "Married",
    occupation: "",
    doctor: "",
    address: "",
    blood: "",
  };
  const [inputValue, setInputValue] = useState(initialState);
  const [image, setImage] = useState([]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  let status = [];
  status.push(
    {
      label: "Married",
      value: "Married",
    },
    {
      label: "Single",
      value: "Single",
    },
    {
      label: "Widow",
      value: "Widow",
    },
    {
      label: "Divorse",
      value: "Divorse",
    }
  );

  let gender = [];
  gender.push(
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    }
  );
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;
  useEffect(() => {
    dispatch(getDoctorAction());
  }, []);
  const getOccupation = useSelector((state) => state.getOccupation);
  const dispatch = useDispatch();
  const { occupations } = getOccupation;

  useEffect(() => {
    dispatch(getOccupationAction());
  }, []);
  let occupation = [];
  occupations?.map((occup) => {
    occupation.push({
      value: occup.id,
      label: occup.name,
    });
  });
  let doctor = [];
  doctors?.map((doc) => {
    doctor.push({
      value: doc.id,
      label: doc.name,
    });
  });
  const bloodType = [
    {
      value: "A+",
      label: "A+",
    },
    {
      value: "A-",
      label: "A-",
    },
    {
      value: "B+",
      label: "B+",
    },
    {
      value: "B-",
      label: "B-",
    },
    {
      value: "O+",
      label: "O+",
    },
    {
      value: "O-",
      label: "O-",
    },
    {
      value: "AB+",
      label: "AB+",
    },
    {
      value: "AB-",
      label: "AB-",
    },
  ];

  const createPatient = useSelector((state) => state.createPatient);
  const { errors } = createPatient;

  const age = getAge(inputValue.birthDate);

  const hadnleAdd = () => {
    setImage([...image, ""]);
  };

  const handleRemoveInput = (index) => {
    const removeInput = [...image];
    removeInput.splice(index, 1);
    setImage(removeInput);
  };

  const handleImage = (e, index) => {
    const updatedImages = [...image];

    updatedImages[index] = e.target.files[0];
    setImage(updatedImages);
  };
  const handleSubmit = () => {
    dispatch(
      createPatientAction(
        inputValue.doctor.value,
        inputValue.occupation.value,
        inputValue.fullname,
        inputValue.phone,
        inputValue.birthDate,
        inputValue.gender.value,
        inputValue.martialStatus.value,
        inputValue.address,
        inputValue.blood.value,
        image,
        reset
      )
    );
  };
  const reset=()=>{
    setInputValue(initialState)
    // setInputValue(initialState)
    setImage([]);
  }
  return (
    <>
      <div className="parent pb-4">
        <div className="head">
          <div>Add New Patient</div>
        </div>

        <div className="container ">
          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Full Name *
            </label>
            <div>
              <input
                type="text"
                className="input w-full"
                name="fullname"
                value={inputValue.fullname}
                onChange={handleInputChange}
                placeholder="Full Name"
              />
            </div>

            <div className="text-red-600">{errors && errors.fullname}</div>
          </div>

          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Phone Number *
            </label>
            <div>
              <input
                type="text"
                className="input w-full"
                name="phone"
                value={inputValue.phone}
                onChange={handleInputChange}
                placeholder="Phone"
                pattern="\d*"
                inputMode="numeric"
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/\D/g, "");
                }}
              />
            </div>
            <div className="text-red-600">{errors && errors.phone}</div>
          </div>

          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Birth Date *
            </label>
            <div>
              <input
                type="date"
                className="input w-full"
                name="birthDate"
                value={inputValue.birthDate}
                onChange={handleInputChange}
                placeholder="Birth Date"
              />
            </div>
            <div className="text-red-600">{errors && errors.birth_date}</div>
          </div>

          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Age *
            </label>
            <div>
              <input
                type="text"
                value={age}
                className="input w-full "
                disabled
                // onChange={handleInputChange}
                placeholder="Age"
              />
            </div>
          </div>

          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Gender *
            </label>
            <div>
              <Select
                options={gender}
                onChange={(selectOptions) => {
                  setInputValue({
                    ...inputValue,
                    gender: selectOptions,
                  });
                }}
                value={inputValue.gender}
              />
            </div>
            <div className="text-red-600">{errors && errors.gender}</div>
          </div>

          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Marital Status *
            </label>
            <div>
              <Select
                // value={selectedOption}
                onChange={(selectOptions) => {
                  setInputValue({
                    ...inputValue,
                    martialStatus: selectOptions,
                  });
                }}
                options={status}
                value={inputValue.martialStatus}
              />
            </div>
          </div>

          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Occupation *
            </label>
            <div>
              <Select
                options={occupation}
                onChange={(selectOptions) => {
                  setInputValue({
                    ...inputValue,
                    occupation: selectOptions,
                  });
                }}
                value={inputValue.occupation}
              />
            </div>
          </div>

          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Doctor *
            </label>
            <div>
              <Select
                options={doctor}
                onChange={(selectOptions) => {
                  setInputValue({
                    ...inputValue,
                    doctor: selectOptions,
                  });
                }}
                value={inputValue.doctor}
              />
            </div>
          </div>

          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Address *
            </label>
            <div>
              <input
                type="text"
                className="input w-full "
                name="address"
                value={inputValue.address}
                onChange={handleInputChange}
                placeholder="Address"
              />
            </div>
          </div>

          <div className="parent-input">
            <label htmlFor="" className="lbl">
              Blood Type *
            </label>
            <div>
              <Select
                options={bloodType}
                onChange={(selectOptions) => {
                  setInputValue({
                    ...inputValue,
                    blood: selectOptions,
                  });
                }}
                value={inputValue.blood}
              />
            </div>
          </div>
        </div>
        <div className="px-3 w-full pb-4  flex justify-end">
          <button className="btn-info" onClick={hadnleAdd}>
            <FaPlus />
          </button>
        </div>

        {image.map((img, index) => {
          return (
            <div key={index} className="flex items-center w-full px-4 m-auto">
              <label htmlFor={`doc-${index}`} className="inputFile">
                <div className="file-container">
                  <h4 className="text-base font-semibold text-center text-gray-700">
                    {img ? img.name : "Upload a file "}
                  </h4>
                  <div className="text-teal-600">
                    <IoIosCloudUpload />
                  </div>
                </div>
                <input
                  type="file"
                  id={`doc-${index}`}
                  // name="doc"
                  onChange={(e) => handleImage(e, index)}
                  name={`image_${index}`}
                  hidden
                />
              </label>

              <button
                className="btn-danger py-2"
                onClick={() => handleRemoveInput(index)}
              >
                <FaTrash />
              </button>
            </div>
          );
        })}
        <div className="px-3">
          <button className="btn-primary mt-2 px-4" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>

      <div className="bottom-space"></div>
    </>
  );
};

export default PatientScreen;

import React, { useEffect } from "react";
import footer from "../../../../Img/footer.jpeg";
import header from "../../../../Img/header.jpeg";
import { getAge } from "../../../../Actions/getAge";
import TreatmentPrint from "./TreatmentPrint";
const PrintPage = React.forwardRef((props, ref) => {
  const { visits, visitId, type,note } = props;
  const stripHtmlTags = (note) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(note, 'text/html');
    return doc.body.textContent || "";
  };

  const textContent = stripHtmlTags(note);
  const treatments = visits
    ?.filter((filter) => filter.id == visitId)
    .flatMap((visit) => visit.treantment);

  return (
    <div ref={ref} className="min-h-full flex flex-col justify-between">
      {/* Image at the top */}
      <img src={header} alt="Top Image" className="w-full h-auto" />
      {visits
        ?.filter((filter) => filter.id == visitId)
        .map((visit) => {
          return visit.treantment.map((treantment, index) => {
            const age=getAge(treantment.patient.birth_date);

            return (
              
              <div key={index}>
                {index == 0 && (
                  
                  <div className="w-11/12 mt-2 m-auto   p-1  flex justify-between px-3">
                    <p>Name : {treantment.patient.fullname}</p>
                    <p>Gender : {treantment.patient.gender}</p>
                    <p className="">Age: {age.split("years")[0]}Yr</p>
                    <p>
                      Date :{" "}
                      {treantment.patient.created_at.toString().split("T")[0]}
                    </p>
                  </div>
                )}
              </div>
            );
          });
        })}

      {/* Content */}
      <div className="flex-grow">
        {/* Conditional content based on type */}
        {type === "treantment" ? (
          <TreatmentPrint tratment={treatments} />
        ) : type == "report" ? (
          <div className="w-11/12 m-auto mt-4 ">{textContent}</div>
        ) : (
          ""
        )}
      </div>

      {/* Image at the bottom */}
      <img
        src={footer}
        alt="Bottom Image"
        className="w-full h-auto absolute bottom-0"
      />
    </div>
  );
});

export default PrintPage;

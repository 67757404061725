import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVisitAction,
  
  saveCompleteVisitAction,
  savePriceVisitAction,
} from "../../../../Actions/VisitAction/visitAction";
import { showPatientAction } from "../../../../Actions/PatientActions/PatientAction";
import { useParams } from "react-router-dom";

const HeadVisit = ({ visits }) => {
  const { id: patient_id } = useParams();
  const dispatch = useDispatch();
  const showPatient = useSelector((state) => state.showPatient);
  const { visits: getVisits } = showPatient;
  const [price, setPrice] = useState(15000);
  useEffect(() => {
    getVisits?.map((visit) => {
      if (visit.price) {
        setPrice(visit.price);
      } else {
        setPrice(15000);
      }
    });
  }, [getVisits, visits]);
  const handleSavePrice = () => {
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(savePriceVisitAction(visits.id, price));
  };
  const handleComplete = () => {
    let state = 0;
    getVisits?.map((visit) => {
      state = visit.state;
    });

    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    if (state == 0) {
      dispatch(saveCompleteVisitAction(visits.id, 1));
    } else if (state == 1) {
      dispatch(saveCompleteVisitAction(visits.id, 0));
    }
  };
  const handleDelete = () => {
    dispatch(deleteVisitAction(visits.id));

    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
  };
  return (
    <div className=" flex flex-col gap-3 lg:flex-row justify-between items-center">
      <div>
        <button className="btn-warning" onClick={handleComplete}>
          {getVisits?.filter((filter)=>visits.id==filter.id).map((visit, index) => {
            return (
              <p>
                {visit?.state == 0
                  ? "Complete"
                  : visit.state == 1
                  ? "Edit"
                  : ""}
              </p>
            );
          })}
        </button>
      </div>

      <div>
        <button className="btn-danger" onClick={handleDelete}>
          Delete
        </button>
      </div>
      <div className="flex gap-2 items-center">
        <input
          type="number"
          className="input"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
        <div>
          <button className="btn-info" onClick={handleSavePrice}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default HeadVisit;

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAIL = "CREATE_USER_FAIL";

export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_FAIL = "FETCH_USER_FAIL";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";

export const SHOW_USER_REQUEST = "SHOW_USER_REQUEST";
export const SHOW_USER_SUCCESS = "SHOW_USER_SUCCESS";
export const SHOW_USER_FAIL = "SHOW_USER_FAIL";

export const FETCH_GET_USER_AUTH_REQUEST = "FETCH_GET_USER_AUTH_REQUEST";
export const FETCH_GET_USER_AUTH_SUCCESS = "FETCH_GET_USER_AUTH_SUCCESS";
export const FETCH_GET_USER_AUTH_FAIL = "FETCH_GET_USER_AUTH_FAIL";

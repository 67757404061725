import Swal from "sweetalert2";
// import {
//   CREATE_MEDICAL_HISTORY_FAIL,
//   CREATE_MEDICAL_HISTORY_SUCCESS,
// } from "../../ActionType/MedicalHistoryTypes/MedicalHistoryTypes";
import { api } from "../../Api/api";
import { loading } from "../Loading";
import { getToken } from "../login";
import { showPatientAction } from "../PatientActions/PatientAction";
import {
  CREATE_INVESTIGATION_FAIL,
  CREATE_INVESTIGATION_REQUEST,
  UPDATE_INVESTIGATION_FAIL,
  UPDATE_INVESTIGATION_SUCCESS,
} from "../../ActionType/InvestigationTypes/Investigation";

export const createInvestigationAction =
  (visit_id, types, results) => async (dispatch) => {
    loading();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    try {
      const resp = await api.post(
        "investigation/create",
        {
          types,
          results,
          //   visit_id
          //   notes,
        },
        config
      );
      const { status, data } = resp;

      if (status == 201) {
        let ids = [];

        data.map((item) => {
          ids.push(item.id);
        });
        dispatch(createInvestigationVisitAction(visit_id, ids));

        dispatch({
          type: CREATE_INVESTIGATION_REQUEST,
          error: "",
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      //   console.log(error);
      if (error.response.status == 422) {
        dispatch({
          type: CREATE_INVESTIGATION_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };

export const createInvestigationVisitAction =
  (visit_id, investigation_id) => async (dispatch) => {
    loading();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    try {
      const resp = await api.post(
        "investigation/create/visit",
        {
          visit_id,
          investigation_id,
        },
        config
      );
      const { status, data } = resp;

      if (status == 201) {
        dispatch({
          type: CREATE_INVESTIGATION_REQUEST,
          error: "",
        });
      }
    } catch (error) {
      //   console.log(error);
      if (error.response.status == 422) {
        dispatch({
          type: CREATE_INVESTIGATION_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };

export const updateInvestigationAction =
  (id, visit_id, types, results) => async (dispatch) => {
    loading();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    try {
      const resp = await api.post(
        "investigation/update",
        {
          id,

          types,
          results,
        },
        config
      );
      const { status, data } = resp;
      // console.log(data)
      if (status == 201) {
        let ids = [];

        data.map((item) => {
          ids.push(item.id);
        });
        dispatch(createInvestigationVisitAction(visit_id, ids));
 
        
      

        dispatch({
          type: UPDATE_INVESTIGATION_SUCCESS,
          error: "",
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      // console.log(error);
      if (error.response.status == 422) {
        dispatch({
          type: UPDATE_INVESTIGATION_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };

export const CREATE_DRUG_REQUEST="CREATE_DRUG_REQUEST";
export const CREATE_DRUG_SUCCESS="CREATE_DRUG_SUCCESS";
export const CREATE_DRUG_FAIL="CREATE_DRUG_FAIL";

export const FETCH_DRUG_REQUEST="FETCH_DRUG_REQUEST";
export const FETCH_DRUG_SUCCESS="FETCH_DRUG_SUCCESS";
export const FETCH_DRUG_FAIL="FETCH_DRUG_FAIL";

export const UPDATE_DRUG_REQUEST="UPDATE_DRUG_REQUEST";
export const UPDATE_DRUG_SUCCESS="UPDATE_DRUG_SUCCESS";
export const UPDATE_DRUG_FAIL="UPDATE_DRUG_FAIL";


import React, { useEffect, useState } from "react";
import { FaUserDoctor } from "react-icons/fa6";
import DoctorModal from "../../Modal/DoctorModal/DoctorModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getDoctorAction,
  updateDoctorAction,
} from "../../Actions/DoctorAction/DoctorAction";
import Loading from "../Loading";
import { FaEdit } from "react-icons/fa";

const Doctor = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getDoctor = useSelector((state) => state.getDoctor);
  const dispatch = useDispatch();
  const { doctors, loading } = getDoctor;
  const [indexx, setIndexx] = useState(null);
  const [name, setName] = useState(null);
  useEffect(() => {
    dispatch(getDoctorAction());
    
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleUpdate = (id) => {
    dispatch(updateDoctorAction(id, name));
  };
  const handleFoucsInput = (id) => {
    handleUpdate(id);
    setIndexx(null);
    setName(null);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="bg-white lg:w-[95%] mt-2  m-auto rounded-sm p-2">
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <p className="text-teal-500">
                <FaUserDoctor />
              </p>
              <p>Doctor</p>
            </div>
            <div>
              <button className="btn-info" onClick={openModal}>
                Add Doctor
              </button>
            </div>
          </div>
           
          <div className="parent-table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {doctors?.map((doctor, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      {index == indexx ? (
                        <td>
                          <input
                            type="text"
                            onBlur={() => handleFoucsInput(doctor.id)}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="input w-full"
                            placeholder="Name"
                          />
                        </td>
                      ) : (
                        <td>{doctor.name}</td>
                      )}
                      <td>
                        <button
                          onClick={() => {
                            setIndexx(index);
                            setName(doctor.name);
                          }}
                          className="btn-primary"
                        >
                          <div className="flex gap-2 items-center">
                            <p>Edit</p>
                            <div><FaEdit /></div>
                          </div>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <DoctorModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
      )}
    </>
  );
};

export default Doctor;

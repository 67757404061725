import React, { useEffect, useState } from "react";
import HeadVisit from "./HeadVisit";
import HistoryCurrentLenuses from "./HistoryCurrentLenuses";
import Investigation from "./Investigation";
import Treantment from "./Treantment";
import VisitImage from "./VisitImage";
import Report from "./Report/Report";

const EachVisit = ({ visits, user }) => {
  return (
    <div className="px-4">
      <HeadVisit visitId={visits.id} visits={visits} />

      {user.admin_type == 1 && (
        <>
          <HistoryCurrentLenuses visits={visits} />

          <Investigation visits={visits} />
          <Treantment visits={visits} />
          <VisitImage visits={visits} />
          <Report visits={visits} />
        </>
      )}

      {user.admin_type == 0 && (
        <>
          <Investigation visits={visits} />

          <VisitImage visits={visits} />
        </>
      )}

      <div className="pb-10"></div>
    </div>
  );
};

export default EachVisit;

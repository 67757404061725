export const CREATE_CURRENLLENUSES_REQUEST="CREATE_CURRENLLENUSES_REQUEST";
export const CREATE_CURRENLLENUSES_SUCCESS="CREATE_CURRENLLENUSES_SUCCESS";
export const CREATE_CURRENLLENUSES_FAIL="CREATE_CURRENLLENUSES_FAIL";

export const FETCH_CURRENLLENUSES_REQUEST="FETCH_CURRENLLENUSES_REQUEST";
export const FETCH_CURRENLLENUSES_SUCCESS="FETCH_CURRENLLENUSES_SUCCESS";
export const FETCH_CURRENLLENUSES_FAIL="FETCH_CURRENLLENUSES_FAIL";

export const UPDATE_CURRENLLENUSES_REQUEST="UPDATE_CURRENLLENUSES_REQUEST";
export const UPDATE_CURRENLLENUSES_SUCCESS="UPDATE_CURRENLLENUSES_SUCCESS";
export const UPDATE_CURRENLLENUSES_FAIL="UPDATE_CURRENLLENUSES_FAIL";
// CUREENT LIN VISIT
export const CREATE_CURRENLLENUSES_VISIT_REQUEST="CREATE_CURRENLLENUSES_VISIT_REQUEST";
export const CREATE_CURRENLLENUSES_VISIT_SUCCESS="CREATE_CURRENLLENUSES_VISIT_SUCCESS";
export const CREATE_CURRENLLENUSES_VISIT_FAIL="CREATE_CURRENLLENUSES_VISIT_FAIL";

export const FETCH_CURRENLLENUSES_VISIT_REQUEST="FETCH_CURRENLLENUSES_VISIT_REQUEST";
export const FETCH_CURRENLLENUSES_VISIT_SUCCESS="FETCH_CURRENLLENUSES_VISIT_SUCCESS";
export const FETCH_CURRENLLENUSES_VISIT_FAIL="FETCH_CURRENLLENUSES_VISIT_FAIL";

export const UPDATE_CURRENLLENUSES_VISIT_REQUEST="UPDATE_CURRENLLENUSES_VISIT_REQUEST";
export const UPDATE_CURRENLLENUSES_VISIT_SUCCESS="UPDATE_CURRENLLENUSES_VISIT_SUCCESS";
export const UPDATE_CURRENLLENUSES_VISIT_FAIL="UPDATE_CURRENLLENUSES_VISIT_FAIL";



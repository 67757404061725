import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";

import { thunk } from "redux-thunk";
import { loginReducer } from "../Reducer/loginReducer";
import {
  createDoctorReducer,
  getDoctorReducer,
  updateDoctorReducer,
} from "../Reducer/DoctorReducer/DoctorReducer";
import {
  createOccupationReducer,
  getOccupationReducer,
  updateOccupationReducer,
} from "../Reducer/OccupationReducer/OccupationReducer";
import {
  createDrugReducer,
  getDrugReducer,
  updateDrugReducer,
} from "../Reducer/DrugReducer/DrugReducer";
import {
  createPatientReducer,
  getPatientReducer,
  showPatientReducer,
  updatePatientReducer,
} from "../Reducer/PatientReducer/PatientReducer";
import { getTotalReducer, getVisitReducer } from "../Reducer/VisitReducer/VisitReducer";
import {
  createTreatmentReducer,
  getTreatmentReducer,
  showTreatmentReducer,
  updateTreatmentReducer,
} from "../Reducer/TreatmentReducer/TreatmentReducer";
import { createUsersReducer, deleteUsersReducer, getUsersAuthReducer, getUsersReducer, showUsersReducer, updateUsersReducer } from "../Reducer/UserReducer/UserReducer";

const rootReducer = combineReducers({
  loginReducer: loginReducer,

  getDoctor: getDoctorReducer,
  createDoctor: createDoctorReducer,
  updateDoctor: updateDoctorReducer,

  getOccupation: getOccupationReducer,
  createOccupation: createOccupationReducer,
  updateOccupation: updateOccupationReducer,

  getDrug: getDrugReducer,
  createDrug: createDrugReducer,
  updateDrug: updateDrugReducer,

  getPatient: getPatientReducer,
  createPatient: createPatientReducer,
  updatePatient: updatePatientReducer,
  showPatient: showPatientReducer,

  getTreatment: getTreatmentReducer,
  createTreatment: createTreatmentReducer,
  updateTreatment: updateTreatmentReducer,
  showTreatment: showTreatmentReducer,
  getTotal:getTotalReducer,
  getVisit: getVisitReducer,

  getUsers:getUsersReducer,
  createUsers:createUsersReducer,
  showUsers:showUsersReducer,
  deleteUsers:deleteUsersReducer,
  updateUsers:updateUsersReducer,
  getUsersAuth:getUsersAuthReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;

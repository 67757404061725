import React, { useEffect } from "react";
// import Logo from "../../Image/Logo.jpg";
import { useDispatch, useSelector } from "react-redux";
// import { getBranchAction } from "../../Actions/BranchAction/BranchAction";
import { useNavigate } from "react-router-dom";
import { getTotalAction } from "../../Actions/VisitAction/visitAction";
import { FaUserDoctor, FaUserInjured, FaUserShield } from "react-icons/fa6";
import { MdSensorOccupied } from "react-icons/md";
import { GiPillDrop } from "react-icons/gi";
const Dashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTotalAction());
  }, []);

  const getTotal = useSelector((state) => state.getTotal);
  const { items, loading } = getTotal;

  const navigate = useNavigate();
  return (
    <React.Fragment>
      {loading ? (
        "loading"
      ) : (
        <div className="flex h-screen">
          <div className="flex-1 bg-gray-200 p-8">
            <h2 className="text-2xl font-semibold mb-4">Dashboard</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              <div
                className="bg-white p-4 rounded-md cursor-pointer shadow-md flex  gap-5 items-center"
                onClick={() => {
                  navigate("doctor");
                }}
              >
                {/* <img src={Logo} className="w-20" alt="" /> */}
                <span className="mr-4 text-teal-500 text-3xl">
                  <FaUserDoctor />
                </span>
                <div>
                  <h3 className="text-lg font-semibold mb-2">Total Doctors</h3>
                  <span>{items?.totalDoctors}</span>
                </div>
              </div>
              {/* end */}
              <div
                className="bg-white p-4 rounded-md cursor-pointer shadow-md flex  gap-5 items-center"
                onClick={() => {
                  navigate("todayPatient");
                }}
              >
                {/* <img src={Logo} className="w-20" alt="" /> */}
                <span className="mr-4 text-teal-500 text-3xl">
                  <FaUserInjured />
                </span>
                <div>
                  <h3 className="text-lg font-semibold mb-2">Total Patients</h3>
                  <span>{items?.totalPatients}</span>
                </div>
              </div>
              {/* end */}
              <div
                className="bg-white p-4 rounded-md cursor-pointer shadow-md flex  gap-5 items-center"
                onClick={() => {
                  navigate("occupation");
                }}
              >
                {/* <img src={Logo} className="w-20" alt="" /> */}
                <span className="mr-4 text-teal-500 text-3xl">
                  <MdSensorOccupied />
                </span>
                <div>
                  <h3 className="text-lg font-semibold mb-2 capitalize">
                    Total occupations
                  </h3>
                  <span>{items?.totalOccupations}</span>
                </div>
              </div>
              {/* end */}

              <div
                className="bg-white p-4 rounded-md cursor-pointer shadow-md flex  gap-5 items-center"
                onClick={() => {
                  navigate("drug");
                }}
              >
                {/* <img src={Logo} className="w-20" alt="" /> */}
                <span className="mr-4 text-teal-500 text-3xl">
                  <GiPillDrop />
                </span>
                <div>
                  <h3 className="text-lg font-semibold mb-2 capitalize">
                    Total Drugs
                  </h3>
                  <span>{items?.totalDrugs}</span>
                </div>
              </div>
              {/* end */}

              <div
                className="bg-white p-4 rounded-md cursor-pointer shadow-md flex  gap-5 items-center"
                onClick={() => {
                  navigate("user");
                }}
              >
                {/* <img src={Logo} className="w-20" alt="" /> */}
                <span className="mr-4 text-teal-500 text-3xl">
                  <FaUserShield />
                </span>
                <div>
                  <h3 className="text-lg font-semibold mb-2 capitalize">
                    Total Users
                  </h3>
                  <span>{items?.totalUsers}</span>
                </div>
              </div>
              {/* end */}
            </div>
          </div>
          <div className="pb-10 lg:pb-0"></div>
        </div>
      )}
    
    </React.Fragment>
  );
};

export default Dashboard;

import { CREATE_DRUG_FAIL, CREATE_DRUG_REQUEST, CREATE_DRUG_SUCCESS, FETCH_DRUG_FAIL, FETCH_DRUG_REQUEST, FETCH_DRUG_SUCCESS, UPDATE_DRUG_FAIL, UPDATE_DRUG_REQUEST, UPDATE_DRUG_SUCCESS } from "../../ActionType/DrugTypes/DrugTypes";

export const getDrugReducer = (
  state = { loading: true, drugs: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_DRUG_REQUEST:
      return {
        loading: true,
      };
    case FETCH_DRUG_SUCCESS:
      return {
        loading: false,
        drugs: action.payload,
        error: "",
      };
    case FETCH_DRUG_FAIL:
      return {
        loading: false,
        drugs: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const createDrugReducer = (
    state = { loading: true, drugs: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_DRUG_REQUEST:
        return {
          loading: true,
        };
      case CREATE_DRUG_SUCCESS:
        return {
          loading: false,
          drugs: action.payload,
          error: "",
        };
      case CREATE_DRUG_FAIL:
        return {
          loading: false,
          drugs: [],
          error: "Check Your Request",
        };
      default:
        return state;
    }
  };


  export const updateDrugReducer = (
    state = { loading: true, drugs: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_DRUG_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_DRUG_SUCCESS:
        return {
          loading: false,
          drugs: action.payload,
          error: "",
        };
      case UPDATE_DRUG_FAIL:
        return {
          loading: false,
          drugs: [],
          error: "Check Your Request",
        };
      default:
        return state;
    }
  };
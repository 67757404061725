export const CREATE_PATIENT_REQUEST="CREATE_PATIENT_REQUEST";
export const CREATE_PATIENT_SUCCESS="CREATE_PATIENT_SUCCESS";
export const CREATE_PATIENT_FAIL="CREATE_PATIENT_FAIL";

export const FETCH_PATIENT_REQUEST="FETCH_PATIENT_REQUEST";
export const FETCH_PATIENT_SUCCESS="FETCH_PATIENT_SUCCESS";
export const FETCH_PATIENT_FAIL="FETCH_PATIENT_FAIL";


export const SHOW_PATIENT_REQUEST="SHOW_PATIENT_REQUEST";
export const SHOW_PATIENT_SUCCESS="SHOW_PATIENT_SUCCESS";
export const SHOW_PATIENT_FAIL="SHOW_PATIENT_FAIL";

export const UPDATE_PATIENT_REQUEST="UPDATE_PATIENT_REQUEST";
export const UPDATE_PATIENT_SUCCESS="UPDATE_PATIENT_SUCCESS";
export const UPDATE_PATIENT_FAIL="UPDATE_PATIENT_FAIL";



export const CREATE_PATIENT_CV_REQUEST="CREATE_PATIENT_CV_REQUEST";
export const CREATE_PATIENT_CV_SUCCESS="CREATE_PATIENT_CV_SUCCESS";
export const CREATE_PATIENT_CV_FAIL="CREATE_PATIENT_CV_FAIL";

export const FETCH_PATIENT_CV_REQUEST="FETCH_PATIENT_CV_REQUEST";
export const FETCH_PATIENT_CV_SUCCESS="FETCH_PATIENT_CV_SUCCESS";
export const FETCH_PATIENT_CV_FAIL="FETCH_PATIENT_CV_FAIL";

export const UPDATE_PATIENT_CV_REQUEST="UPDATE_PATIENT_CV_REQUEST";
export const UPDATE_PATIENT_CV_SUCCESS="UPDATE_PATIENT_CV_SUCCESS";
export const UPDATE_PATIENT_CV_FAIL="UPDATE_PATIENT_CV_FAIL";



export const CREATE_OCCUPATION_REQUEST="CREATE_OCCUPATION_REQUEST";
export const CREATE_OCCUPATION_SUCCESS="CREATE_OCCUPATION_SUCCESS";
export const CREATE_OCCUPATION_FAIL="CREATE_OCCUPATION_FAIL";

export const FETCH_OCCUPATION_REQUEST="FETCH_OCCUPATION_REQUEST";
export const FETCH_OCCUPATION_SUCCESS="FETCH_OCCUPATION_SUCCESS";
export const FETCH_OCCUPATION_FAIL="FETCH_OCCUPATION_FAIL";

export const UPDATE_OCCUPATION_REQUEST="UPDATE_OCCUPATION_REQUEST";
export const UPDATE_OCCUPATION_SUCCESS="UPDATE_OCCUPATION_SUCCESS";
export const UPDATE_OCCUPATION_FAIL="UPDATE_OCCUPATION_FAIL";


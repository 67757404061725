import { api } from "../../Api/api";
import Swal from "sweetalert2";
import { getToken } from "../login";
import {
  CREATE_DRUG_FAIL,
  CREATE_DRUG_REQUEST,
  CREATE_DRUG_SUCCESS,
  FETCH_DRUG_FAIL,
  FETCH_DRUG_REQUEST,
  FETCH_DRUG_SUCCESS,
  UPDATE_DRUG_FAIL,
  UPDATE_DRUG_REQUEST,
  UPDATE_DRUG_SUCCESS,
} from "../../ActionType/DrugTypes/DrugTypes";
export const getDrugAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: FETCH_DRUG_REQUEST,
  });
  try {
    const resp = await api.get("drug", config);
    const { status, data } = resp;

    if (status == 200) {
      dispatch({
        type: FETCH_DRUG_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_DRUG_FAIL,
      payload: [],
    });
    if(error.response.status==401){
      localStorage.removeItem("token")
      window.location.reload();
    }
  }
};
export const createDrugAction = (name) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: CREATE_DRUG_REQUEST,
  });
  try {
    const resp = await api.post("drug/create", { name }, config);
    const { status, data } = resp;
    dispatch({
      type: CREATE_DRUG_SUCCESS,
    });
    if (status == 201) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",

        timer: 1500,
      });
      dispatch(getDrugAction());
      dispatch(getDrugAction());
    }
  } catch (error) {
    dispatch({
      type: CREATE_DRUG_FAIL,
    });
    Swal.fire({
      icon: "error",

      title: "Check Your Request",
      //   showConfirmButton: false,
      timer: 1500,
    });
  }
};

export const updateDrugAction = (id, name) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: UPDATE_DRUG_REQUEST,
  });
  try {
    const resp = await api.patch("drug/update/" + id, { name }, config);
    const { status, data } = resp;
    dispatch({
      type: UPDATE_DRUG_SUCCESS,
    });
    if (status == 200) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been Upadated",

        timer: 1500,
      });
      dispatch(getDrugAction());
      dispatch(getDrugAction());
    }
  } catch (error) {
    dispatch({
      type: UPDATE_DRUG_FAIL,
    });
    Swal.fire({
      icon: "error",

      title: "Check Your Request",
      //   showConfirmButton: false,
      timer: 1500,
    });
  }
};

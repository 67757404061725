import Swal from "sweetalert2";
import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_REPORT_FAIL,
  CREATE_REPORT_SUCCESS,
} from "../../ActionType/ReportType/ReportType";
import { loading } from "../Loading";

export const createReportAction = (visit_id, note) => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  console.log(visit_id, note);
  try {
    const resp = await api.post(
      "report/create",
      {
        note,
        visit_id,
      },
      config
    );
    const { status, data } = resp;

    if (status == 201) {
      dispatch({
        type: CREATE_REPORT_SUCCESS,
        error: "",
      });
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been saved",
        timer: 1500,
      });
    }
  } catch (error) {
    if (error.response.status == 422) {
      dispatch({
        type: CREATE_REPORT_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

export const updateReportAction = (ids, visit_id, note) => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.post(
      "report/update",
      {
        ids,
        note,
        visit_id,
      },
      config
    );
    const { status, data } = resp;

    if (status == 200) {
      dispatch({
        type: CREATE_REPORT_SUCCESS,
        error: "",
      });
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been saved",
        timer: 1500,
      });
    }
  } catch (error) {
    if (error.response.status == 422) {
      dispatch({
        type: CREATE_REPORT_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Select from "react-select";
import { FaPlus, FaPrint, FaTrash } from "react-icons/fa";
import { getDrugAction } from "../../../../Actions/DrugAction/DrugAction";
import PrintPage from "../PrintPage/PrintPage";
import { useReactToPrint } from "react-to-print";
import { MdOutlineSaveAlt } from "react-icons/md";
import {
  createTreatmentAction,
  delteTreatmentAction,
} from "../../../../Actions/TreatmentAction/TreatmentAction";
import { showPatientAction } from "../../../../Actions/PatientActions/PatientAction";

const Treantment = ({ visits }) => {
  const { id: patient_id } = useParams();
  const dispatch = useDispatch();
  const showPatient = useSelector((state) => state.showPatient);
  const { loading, visits: getVisits } = showPatient;

  const [state, setState] = useState(null);
  useEffect(() => {
    let temp = {};
    getVisits
      .filter((filter) => filter.id == visits.id)
      .map((visit) => {
        setState(visit.state);
      });
  }, [getVisits, visits]);
  const handleSubmit = (event) => {
    event.preventDefault();
    if (drugList.length <= 0) {
      setError("required");
      return;
    }
    let drugs_id = [];
    drugList.map((drug) => {
      drugs_id.push(drug.value);
    });
    let visitId = 0;
    getVisits?.map((visit) => {
      visitId = visit.id;
    });
    dispatch(createTreatmentAction(visitId, drugs_id, patient_id, noteList));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));

    setError("");
    setDrugList([]);
    setNoteList([]);
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const getDrug = useSelector((state) => state.getDrug);
  const { drugs } = getDrug;
  let optionDrugs = [];
  drugs?.map((drug) => {
    optionDrugs.push({
      value: drug?.id,
      label: drug?.name,
    });
  });

  useEffect(() => {
    dispatch(getDrugAction());
  }, []);
  const [drug, setDrug] = useState();
  const [note, setNote] = useState("");
  const [error, setError] = useState("");
  const [drugList, setDrugList] = useState([]);
  const [noteList, setNoteList] = useState([]);
  const handleAdd = (event) => {
    event.preventDefault();
    if (drug == "" || drug == null) {
      setError("Required");
      return;
    }
    setNoteList([...noteList, note]);
    setDrugList([
      ...drugList,
      {
        label: drug.label,
        value: drug.value,
      },
    ]);
    setDrug("");
    setError("");
    setNote("");
  };

  const handleRemove = (index) => {
    const removeInput = [...drugList];

    removeInput.splice(index, 1);
    setDrugList(removeInput);

    const removeInputNote = [...noteList];
    removeInputNote.splice(index, 1);
    setNoteList(removeInputNote);
  };

  const handleDelete = (id) => {
    dispatch(delteTreatmentAction(id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
  };

  return (
    <div className="mt-3">
      <div className=" bg-blue-500 p-2 text-white rounded-sm">
        <p>TREANTMENT</p>
      </div>

      <form action=" mt-4" className="">
        <div className="flex lg:flex-row flex-col items-center gap-4 mt-4">
          <div className="w-full flex flex-col">
            <Select
              isDisabled={state == 1 ? true : false}
              options={optionDrugs}
              onChange={(selected) => {
                setDrug(selected);
              }}
              value={drug || ""}
            />

            <div className="text-red-600 ">{error}</div>
          </div>

          <div className="w-full">
            <input
              type="text"
              className="input p-0 w-full"
              placeholder="Dose And Duration"
              onChange={(e) => setNote(e.target.value)}
              disabled={state == 1 ? true : false}
              value={note}
            />
          </div>
          <div className="">
            <button
              className={
                state == 1 ? "btn-primary cursor-not-allowed" : "btn-primary"
              }
              disabled={state == 1 ? true : false}
              onClick={handleAdd}
            >
              <FaPlus />
            </button>
          </div>
        </div>
        <hr />
        <div>
          <button
            className={
              state == 1
                ? "cursor-not-allowed btn-info mt-4 duration-500 flex gap-3 items-center"
                : "btn-info mt-4 duration-500 flex gap-3 items-center"
            }
            onClick={handleSubmit}
            disabled={state == 1 ? true : false}
          >
            Save
            <MdOutlineSaveAlt />
          </button>
        </div>
      </form>

      {drugList.length > 0 && (
        <table className="mt-2">
          <thead>
            <tr>
              <th>#</th>
              <th>Drug</th>
              <th>Note</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {drugList.map((d, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{d?.label}</td>
                  <td>{noteList[index]}</td>
                  <td>
                    <button
                      className="btn-danger"
                      onClick={(e) => {
                        e.preventDefault();
                        handleRemove(index);
                      }}
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div hidden>
        <PrintPage ref={componentRef} visitId={visits.id} visits={getVisits} type={"treantment"} />
      </div>

      <table className="mt-5">
        <thead>
          <tr>
            <th>#</th>
            <th>Drug</th>
            <th>Note</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {getVisits
            ?.filter((filter) => filter.id == visits.id)
            .map((visit, vindex) => {
              return visit?.treantment.map((treantment, index) => {
                return (
                  <tr key={index + vindex}>
                    <td>{index + 1}</td>
                    <td>{treantment?.drug?.name}</td>
                    <td>{treantment?.note}</td>
                    <td>
                      <button
                        className={state==1?"btn-danger cursor-not-allowed":"btn-danger"}
                        disabled={state==1?true:false}
                        onClick={(e) => {
                          e.preventDefault();
                          // console.log(treantment)
                          handleDelete(treantment.id);
                        }}
                      >
                        <FaTrash />
                      </button>
                    </td>
                  </tr>
                );
              });
            })}
        </tbody>
      </table>
      <hr />
      <div className="pb-4"></div>
      <button
        onClick={handlePrint}
        className="btn-primary flex gap-4 items-center"
      >
        Print <FaPrint />
      </button>
      <div className="pb-6"></div>
    </div>
  );
};

export default Treantment;

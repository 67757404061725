import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
// import faker from 'faker';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const ChartPatient = ({ visits }) => {
  const [x, setXDate] = useState([]);
  const [y, setYValue] = useState([]);
  const [d, setD] = useState("");
  useEffect(() => {
    // change("t4");
    // setD("t4")
    let tempX = [];
    let tempY = [];
    visits.forEach((e) => {
      tempX.push(e.created_at.split("T")[0]);
      e.investigation.forEach((d) => {
        if (d.type === "BMI") {
          tempY.push(d.result);
        }
      });
      setXDate(tempX);
      // setBMIValue(tempY)
    });
  }, []);

  const change = (pro) => {
    setD(pro);
    let tempY = [];
    visits.forEach((e) => {
      e.investigation.forEach((d) => {
        if (d.type === pro) {
          tempY.push(d.result);
        }
      });

      setYValue(tempY);
    });
  };
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "Chart.js Bar Chart",
      },
    },
  };

  const data = {
    labels: x,
    datasets: [
      {
        label: d,
        data: y,
        backgroundColor: "rgba(0, 0, 0, 0.7)",
      },
    ],
  };

  return (
    <div>
      ChartPatient
      <Bar options={options} data={data} />
      <div className="flex gap-2 mt-2">
        <button
          style={{ backgroundColor: "#115e59", color: "#ffffff" }}
          className="px-4 py-2"
          onClick={() => change("atop")}
        >
          ATOP
        </button>

        <button
          style={{ backgroundColor: "#5eead4", color: "#000000" }}
          className="px-4 py-2"
          onClick={() => change("t4")}
        >
          T4
        </button>

        <button
          style={{ backgroundColor: "#ffb400", color: "#000000" }}
          className="px-4 py-2"
          onClick={() => change("d3")}
        >
          D3
        </button>

        <button
          style={{ backgroundColor: "#ff5733", color: "#ffffff" }}
          onClick={() => change("tsh")}
          className="px-4 py-2"
        >
          TSH
        </button>
      </div>
      <div className="pb-2"></div>
    </div>
  );
};

export default ChartPatient;

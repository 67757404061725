import { CREATE_TREATMENT_FAIL, CREATE_TREATMENT_REQUEST, CREATE_TREATMENT_SUCCESS, FETCH_TREATMENT_FAIL, FETCH_TREATMENT_REQUEST, FETCH_TREATMENT_SUCCESS, SHOW_TREATMENT_FAIL, SHOW_TREATMENT_REQUEST, SHOW_TREATMENT_SUCCESS, UPDATE_TREATMENT_FAIL, UPDATE_TREATMENT_REQUEST, UPDATE_TREATMENT_SUCCESS } from "../../ActionType/TreatmentTypes/TreatmentTypes";

  
  export const getTreatmentReducer = (
    state = { loading: true, treatments: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case FETCH_TREATMENT_REQUEST:
        return {
          loading: true,
        };
      case FETCH_TREATMENT_SUCCESS:
        return {
          loading: false,
          treatments: action.payload,
          error: "",
        };
      case FETCH_TREATMENT_FAIL:
        return {
          loading: false,
          treatments: [],
          error: "Check Your Request",
        };
      default:
        return state;
    }
  };
  
  export const showTreatmentReducer = (
    state = { loading: true, treatments: [], visits: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_TREATMENT_REQUEST:
        return {
          loading: true,
        };
      case SHOW_TREATMENT_SUCCESS:
        return {
          loading: false,
          treatments: action.payload.treatments,
          visits: action.payload.visits,
          error: "",
        };
      case SHOW_TREATMENT_FAIL:
        return {
          loading: false,
          treatments: [],
          error: "Check Your Request",
        };
      default:
        return state;
    }
  };
  
  export const createTreatmentReducer = (
    state = { loading: true, treatments: [], errors: "" },
    action
  ) => {
    // console.log(action.error)
    switch (action.type) {
      case CREATE_TREATMENT_REQUEST:
        return {
          loading: true,
        };
  
      case CREATE_TREATMENT_SUCCESS:
        return {
          loading: false,
          treatments: action.payload,
          error: "",
        };
      case CREATE_TREATMENT_FAIL:
        return {
          loading: false,
          treatments: [],
          errors: action.error,
        };
      default:
        return state;
    }
  };
  
  export const updateTreatmentReducer = (
    state = { loading: true, treatments: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_TREATMENT_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_TREATMENT_SUCCESS:
        return {
          loading: false,
          treatments: action.payload,
          error: "",
        };
      case UPDATE_TREATMENT_FAIL:
        return {
          loading: false,
          treatments: [],
          error: "Check Your Request",
        };
      default:
        return state;
    }
  };
  
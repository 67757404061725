import React, { useEffect, useState } from "react";
import { FaPlus, FaSave, FaTrash } from "react-icons/fa";
import { IoIosCloudUpload } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import {
  createVisitCvAction,
  deleteVisitCvAction,
} from "../../../../Actions/VisitAction/visitAction";
import { MdOutlineSaveAlt } from "react-icons/md";
import { showPatientAction } from "../../../../Actions/PatientActions/PatientAction";
import { Link, useParams } from "react-router-dom";
import { AiFillFilePdf } from "react-icons/ai";

const VisitImage = ({ visits }) => {
  const showPatient = useSelector((state) => state.showPatient);
  const { loading, visits: getVisit } = showPatient;
  const [state, setState] = useState(null);
  useEffect(() => {
    let temp = {};
    getVisit
      .filter((filter) => filter.id == visits.id)
      .map((visit) => {
        console.log(visit);
        setState(visit.state);
      });
  }, [getVisit]);
  const { id: patient_id } = useParams();
  const [image, setImage] = useState([]);
  const hadnleAdd = () => {
    setImage([...image, ""]);
  };
  const dispatch = useDispatch();
  const handleRemoveInput = (index) => {
    const removeInput = [...image];
    removeInput.splice(index, 1);
    setImage(removeInput);
  };

  const handleImage = (e, index) => {
    const updatedImages = [...image];

    updatedImages[index] = e.target.files[0];
    setImage(updatedImages);
  };
  const handleSubmit = () => {
    dispatch(createVisitCvAction(visits?.id, image));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));

    setImage([]);
    // handleSubmit()
  };

  const handleDelete = (id) => {
    dispatch(deleteVisitCvAction(id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
  };
  const getFileExtension = (url) => {
    const parts = url.split(".");
    return parts[parts.length - 1];
  };
  return (
    <div className="mt-3">
      <div className=" bg-teal-500 p-2 text-white rounded-sm">
        <p>Visit Image</p>
      </div>
      <div className="px-3 w-full pb-4 mt-4  flex justify-end">
        <button
          className={state == 1 ? "btn-info cursor-not-allowed" : "btn-info"}
          onClick={hadnleAdd}
          disabled={state == 1 ? true : false}
        >
          <FaPlus />
        </button>
      </div>
      {image.map((img, index) => {
        return (
          <div key={index} className="flex items-center w-full px-4 m-auto">
            <label htmlFor={`doc-${index}`} className="inputFile">
              <div className="file-container">
                <h4 className="text-base font-semibold text-center text-gray-700">
                  {img ? img.name : "Upload a file "}
                </h4>
                <div className="text-teal-600">
                  <IoIosCloudUpload />
                </div>
              </div>
              <input
                type="file"
                id={`doc-${index}`}
                // name="doc"
                onChange={(e) => handleImage(e, index)}
                name={`image_${index}`}
                hidden
              />
            </label>

            <button
              className="btn-danger py-2"
              onClick={() => handleRemoveInput(index)}
            >
              <FaTrash />
            </button>
          </div>
        );
      })}

      <button
        className={
          state == 1
            ? "btn-info my-2 flex gap-3 items-center cursor-not-allowed"
            : "btn-info my-2 flex gap-3 items-center"
        }
        onClick={handleSubmit}
        disabled={state == 1 ? true : false}
      >
        Save
        <MdOutlineSaveAlt />
      </button>
      <hr />
      <div className="parent-table">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>

          <tbody>
            {getVisit
              ?.filter((filter) => filter.id == visits.id)
              .map((visit, index) => {
                return visit.visitCv.map((visitCv, index) => {
                  const fileExtension = getFileExtension(visitCv.cv); // Extract the file extension

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {fileExtension == "pdf" ? (
                          <Link
                            target="_blank"
                            to={visitCv.cv}
                            className="text-4xl text-red-800"
                          >
                            <AiFillFilePdf />
                          </Link>
                        ) : (
                          <Link target="_blank" to={visitCv.cv} className="">
                            <img
                              src={visitCv.cv}
                              className="w-20 object-cover"
                              alt=""
                            />
                          </Link>
                        )}
                      </td>
                      <td>
                        <button
                          className={
                            state == 1
                              ? "btn-danger cursor-not-allowed"
                              : "btn-danger"
                          }
                          disabled={state == 1 ? true : false}
                          onClick={() => {
                            handleDelete(visitCv.id);
                          }}
                        >
                          <FaTrash />
                        </button>
                      </td>
                    </tr>
                  );
                });
              })}
          </tbody>
        </table>
      </div>

      <div className="pb-10"></div>
    </div>
  );
};

export default VisitImage;

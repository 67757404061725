import Swal from "sweetalert2";
import { USER_LOGIN_FAIL, USER_LOGIN_SUCCESS } from "../ActionType/loginTypes";
import { api, loginApi } from "../Api/api";

const loginAction = (email, password) => async (dispatch) => {
  try {
    const response = await loginApi.post("login", { email, password });
    const { status, data } = response;

    if (status == 200) {
      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data.userInfo,
      });
      localStorage.setItem("token", data.token);
    } else if (status == 401) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: [],
      });
    }
  } catch (error) {
    console.log(error);
    if(error.response.status==401){
      Swal.fire({
        icon:"error",
        timer:3000,
        title:"Logged In",
        text:"Invalid credential Please Check Your Email Or Password"
      })
      return
    }
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: [],
    });
  }
};

export default loginAction;

export const getToken = () => {
  return localStorage.getItem("token");
};

export const logOutAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  try {
    const response = await api.post("user/logout", {}, config);
    const { status, data } = response;

    if (status == 200) {
      dispatch({
        type: USER_LOGIN_FAIL,
        payload: [],
      });
      localStorage.removeItem("token");
      window.location.reload();
    }
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: [],
    });
  }
};

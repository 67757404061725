import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createMedicalHistoryAction,
  updateMedicalHistoryAction,
} from "../../../../Actions/MedicalHistoryAction/MedicalHistoryAction";

const MedicalHistory = ({ medicalHistory }) => {
  const { id } = useParams();
  const [inputValue, setInputValue] = useState({});
  const [isOn, setIsOn] = useState(false);

  const handleChange = (event) => {
    setIsOn(!isOn);
  };
  useEffect(() => {
    let temp = {};

    if (medicalHistory?.length > 0) {
      medicalHistory?.map((medical) => {
        temp[medical.type + "Range"] = medical.range;
        temp[medical.type] = medical.note;
        setInputValue({ ...temp });
      });
    } else {
      setInputValue({
        medicalHistory: "",
        surgicalHistory: "",
        hospitalization: "",
        bloodTransfusion: "",
        gravida: "",
        pariety: "",
        abortion: "",
        dead: "",
        other: "",
     
        hypertension: "",
        DM: "",
        Thyroid: "",
        Heart: "",
        Asthma: "",
        Allergy: "",
        Cigarette: "",
        DrinkingAlcohol: "",
        // range
        medicalHistoryRange: "0",
        surgicalHistoryRange: "0",
        hospitalizationRange: "0",
        bloodTransfusionRange: "0",
        gravidaRange: "0",
        parietyRange: "0",
        abortionRange: "0",
        deadRange: "0",
        otherRange: "0",
        hypertensionRange: "0",
        DMRange: "0",
        ThyroidRange: "0",
        HeartRange: "0",
        AsthmaRange: "0",
        AllergyRange: "0",
        CigaretteRange: "0",
        DrinkingAlcoholRange: "Unspecified",
      });
    }
  }, [medicalHistory]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const dispatch = useDispatch();

  const handleSubmit = (event) => {
    event.preventDefault();
    let ranges = [];
    let types = [];
    let notes = [];
    Object.entries(inputValue).forEach(([key, value]) => {
      if (key.toLocaleLowerCase().includes("range")) {
   
        ranges.push(value?.toString());
      } else {
        types.push(key?.toString());
        notes.push(value?.toString());
      }
    });
    let ids = [];

    if (medicalHistory?.length > 0) {
      medicalHistory?.map((medical) => {
        ids.push(medical?.id);
      });
      dispatch(updateMedicalHistoryAction(ids, id, types, ranges, notes));
    } else {
      dispatch(createMedicalHistoryAction(id, types, ranges, notes));
    }
  };
  return (
    <div className="w-full">
      {/* {medicalHistory?.id} */}
      <form action="" className="flex flex-col px-5">
        <div className="flex flex-col">
          <label htmlFor="" className="capitalize">
            medical history{" "}
          </label>
          <div>
            <textarea
              name="medicalHistory"
              value={inputValue.medicalHistory}
              onChange={handleInputChange}
              placeholder="Medical History"
              className="input mt-2 w-full"
              id=""
            ></textarea>
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="">Surgical History </label>
          <div>
            <textarea
              name="surgicalHistory"
              value={inputValue.surgicalHistory}
              onChange={handleInputChange}
              placeholder="Surgical History"
              className="input mt-2 w-full"
              id=""
            ></textarea>
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="">Hospitalization </label>
          <div>
            <textarea
              name="hospitalization"
              value={inputValue.hospitalization}
              onChange={handleInputChange}
              placeholder="Hospitalization"
              className="input mt-2 w-full"
              id=""
            ></textarea>
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="">Blood transfusion </label>
          <div>
            <textarea
              name="bloodTransfusion"
              value={inputValue.bloodTransfusion}
              onChange={handleInputChange}
              placeholder="Blood Transfusion"
              className="input mt-2 w-full"
              id=""
            ></textarea>
          </div>
        </div>

        <hr />

        <div className="my-4 p-2 parent-table border shadow-sm border-gray-200 ">
          <table className="shadow-sm">
            <tbody>
              <tr>
                <td colSpan={3}>
                  <h3 className="font-bold text-xl">Obstetrics</h3>
                </td>
              </tr>
              <tr>
                <td className="border ">Gravida</td>
                <td className="border ">
                  <select
                    name="gravidaRange"
                    onChange={handleInputChange}
                    value={inputValue.gravidaRange}
                    className="input w-full"
                    id=""
                  >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                      (select, index) => {
                        return (
                          <option key={index} value={select}>
                            {select}
                          </option>
                        );
                      }
                    )}
                  </select>
                </td>
                <td className="border ">
                  <textarea
                    name="gravida"
                    value={inputValue.gravida}
                    onChange={handleInputChange}
                    className="input w-full"
                    placeholder="Gravida"
                    id=""
                  ></textarea>
                </td>
              </tr>

              <tr>
                <td className="border ">Pariety</td>
                <td className="border ">
                  <select
                    name="parietyRange"
                    value={inputValue.parietyRange}
                    onChange={handleInputChange}
                    className="input w-full"
                    id=""
                  >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                      (select, index) => {
                        return (
                          <option key={index} value={select}>
                            {select}
                          </option>
                        );
                      }
                    )}
                  </select>
                </td>
                <td className="border ">
                  <textarea
                    name="pariety"
                    value={inputValue.pariety}
                    onChange={handleInputChange}
                    className="input w-full"
                    placeholder="Pariety"
                    id=""
                  ></textarea>
                </td>
              </tr>

              <tr>
                <td className="border ">Abortion</td>
                <td className="border ">
                  <select
                    name="abortionRange"
                    value={inputValue.abortionRange}
                    onChange={handleInputChange}
                    className="input w-full"
                    id=""
                  >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                      (select, index) => {
                        return (
                          <option key={index} value={select}>
                            {select}
                          </option>
                        );
                      }
                    )}
                  </select>
                </td>
                <td className="border ">
                  <textarea
                    name="abortion"
                    value={inputValue.abortion}
                    onChange={handleInputChange}
                    className="input w-full"
                    placeholder="Abortion"
                    id=""
                  ></textarea>
                </td>
              </tr>

              <tr cla>
                <td className="border ">Dead</td>
                <td className="border ">
                  <select
                    name="deadRange"
                    value={inputValue.deadRange}
                    onChange={handleInputChange}
                    className="input w-full"
                    id=""
                  >
                    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                      (select, index) => {
                        return (
                          <option key={index} value={select}>
                            {select}
                          </option>
                        );
                      }
                    )}
                  </select>
                </td>
                <td className="border ">
                  <textarea
                    name="dead"
                    value={inputValue.dead}
                    onChange={handleInputChange}
                    className="input w-full"
                    placeholder="Dead"
                    id=""
                  ></textarea>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <table>
          <thead className="bg-info">
            <tr className="border">
              <th className="w-25 border">Chronic Disease</th>
              <th className="border">Yes/No</th>
              <th className="border">Drugs & Other Details</th>
            </tr>
          </thead>
          <tbody>
            <tr className="border">
              <td className="border">Hypertension</td>
              <td className="border">
                {" "}
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    hidden
                    checked={inputValue.hypertensionRange == 1 ? true : false}
                    onChange={(e) => {
                      setInputValue({
                        ...inputValue,
                        hypertensionRange:
                          inputValue.hypertensionRange == 0 ? 1 : 0,
                      });
                    }}
                  />
                  <div
                    className={`w-11 h-6 ${
                      inputValue.hypertensionRange == 1
                        ? "bg-green-500"
                        : "bg-gray-300"
                    } rounded-full relative transition-colors duration-200 ease-in-out peer-checked:bg-blue-600`}
                  >
                    <span
                      className={`absolute left-1 top-1 w-4 h-4 ${
                        inputValue.hypertensionRange == 1
                          ? "bg-white"
                          : "bg-white"
                      } rounded-full transition-transform duration-200 ease-in-out transform ${
                        inputValue.hypertensionRange == 1
                          ? "translate-x-full"
                          : ""
                      }`}
                    />
                  </div>
                </label>
              </td>
              <td className="border">
                {" "}
                <textarea
                  name="hypertension"
                  value={inputValue.hypertension}
                  onChange={handleInputChange}
                  className="input w-full"
                  placeholder="hypertension"
                  id=""
                ></textarea>
              </td>
            </tr>

            <tr className="border">
              <td className="border">DM</td>
              <td className="border">
                {" "}
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    hidden
                    checked={inputValue.DMRange == 1 ? true : false}
                    onChange={(e) => {
                      setInputValue({
                        ...inputValue,
                        DMRange: inputValue.DMRange == 0 ? 1 : 0,
                      });
                    }}
                  />
                  <div
                    className={`w-11 h-6 ${
                      inputValue.DMRange == 1 ? "bg-green-500" : "bg-gray-300"
                    } rounded-full relative transition-colors duration-200 ease-in-out peer-checked:bg-blue-600`}
                  >
                    <span
                      className={`absolute left-1 top-1 w-4 h-4 ${
                        inputValue.DMRange == 1 ? "bg-white" : "bg-white"
                      } rounded-full transition-transform duration-200 ease-in-out transform ${
                        inputValue.DMRange == 1 ? "translate-x-full" : ""
                      }`}
                    />
                  </div>
                </label>
              </td>
              <td className="border">
                {" "}
                <textarea
                  name="DM"
                  value={inputValue.DM}
                  onChange={handleInputChange}
                  className="input w-full"
                  placeholder="DM"
                  id=""
                ></textarea>
              </td>
            </tr>

            <tr className="border">
              <td className="border">Thyroid</td>
              <td className="border">
                {" "}
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    hidden
                    checked={inputValue.ThyroidRange == 1 ? true : false}
                    onChange={(e) => {
                      setInputValue({
                        ...inputValue,
                        ThyroidRange: inputValue.ThyroidRange == 0 ? 1 : 0,
                      });
                    }}
                  />
                  <div
                    className={`w-11 h-6 ${
                      inputValue.ThyroidRange == 1
                        ? "bg-green-500"
                        : "bg-gray-300"
                    } rounded-full relative transition-colors duration-200 ease-in-out peer-checked:bg-blue-600`}
                  >
                    <span
                      className={`absolute left-1 top-1 w-4 h-4 ${
                        inputValue.ThyroidRange == 1 ? "bg-white" : "bg-white"
                      } rounded-full transition-transform duration-200 ease-in-out transform ${
                        inputValue.ThyroidRange == 1 ? "translate-x-full" : ""
                      }`}
                    />
                  </div>
                </label>
              </td>
              <td className="border">
                {" "}
                <textarea
                  name="Thyroid"
                  value={inputValue.Thyroid}
                  onChange={handleInputChange}
                  className="input w-full"
                  placeholder="Thyroid"
                  id=""
                ></textarea>
              </td>
            </tr>

            <tr className="border">
              <td className="border">HeartRange</td>
              <td className="border">
                {" "}
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    hidden
                    checked={inputValue.HeartRange == 1 ? true : false}
                    onChange={(e) => {
                      setInputValue({
                        ...inputValue,
                        HeartRange: inputValue.HeartRange == 0 ? 1 : 0,
                      });
                    }}
                  />
                  <div
                    className={`w-11 h-6 ${
                      inputValue.HeartRange == 1
                        ? "bg-green-500"
                        : "bg-gray-300"
                    } rounded-full relative transition-colors duration-200 ease-in-out peer-checked:bg-blue-600`}
                  >
                    <span
                      className={`absolute left-1 top-1 w-4 h-4 ${
                        inputValue.HeartRange == 1 ? "bg-white" : "bg-white"
                      } rounded-full transition-transform duration-200 ease-in-out transform ${
                        inputValue.HeartRange == 1 ? "translate-x-full" : ""
                      }`}
                    />
                  </div>
                </label>
              </td>
              <td className="border">
                {" "}
                <textarea
                  name="Heart"
                  value={inputValue.Heart}
                  onChange={handleInputChange}
                  className="input w-full"
                  placeholder="Heart"
                  id=""
                ></textarea>
              </td>
            </tr>

            <tr className="border">
              <td className="border">Asthma</td>
              <td className="border">
                {" "}
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    hidden
                    checked={inputValue.AsthmaRange == 1 ? true : false}
                    onChange={(e) => {
                      setInputValue({
                        ...inputValue,
                        AsthmaRange: inputValue.AsthmaRange == 0 ? 1 : 0,
                      });
                    }}
                  />
                  <div
                    className={`w-11 h-6 ${
                      inputValue.AsthmaRange == 1
                        ? "bg-green-500"
                        : "bg-gray-300"
                    } rounded-full relative transition-colors duration-200 ease-in-out peer-checked:bg-blue-600`}
                  >
                    <span
                      className={`absolute left-1 top-1 w-4 h-4 ${
                        inputValue.AsthmaRange == 1 ? "bg-white" : "bg-white"
                      } rounded-full transition-transform duration-200 ease-in-out transform ${
                        inputValue.AsthmaRange == 1 ? "translate-x-full" : ""
                      }`}
                    />
                  </div>
                </label>
              </td>
              <td className="border">
                {" "}
                <textarea
                  name="Asthma"
                  value={inputValue.Asthma}
                  onChange={handleInputChange}
                  className="input w-full"
                  placeholder="Asthma"
                  id=""
                ></textarea>
              </td>
            </tr>

            <tr className="border">
              <td className="border">Allergy</td>
              <td className="border">
                {" "}
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    hidden
                    checked={inputValue.AllergyRange == "1"}
                    onChange={(e) => {
                      setInputValue({
                        ...inputValue,
                        AllergyRange: inputValue.AllergyRange == 0 ? 1 : 0,
                      });
                    }}
                  />
                  <div
                    className={`w-11 h-6 ${
                      inputValue.AllergyRange == 1
                        ? "bg-green-500"
                        : "bg-gray-300"
                    } rounded-full relative transition-colors duration-200 ease-in-out peer-checked:bg-blue-600`}
                  >
                    <span
                      className={`absolute left-1 top-1 w-4 h-4 ${
                        inputValue.AllergyRange == 1 ? "bg-white" : "bg-white"
                      } rounded-full transition-transform duration-200 ease-in-out transform ${
                        inputValue.AllergyRange == 1 ? "translate-x-full" : ""
                      }`}
                    />
                  </div>
                </label>
              </td>
              <td className="border">
                {" "}
                <textarea
                  name="Allergy"
                  value={inputValue.Allergy}
                  onChange={handleInputChange}
                  className="input w-full"
                  placeholder="Allergy"
                  id=""
                ></textarea>
              </td>
            </tr>

            <tr className="border">
              <td className="border">Cigarette</td>
              <td className="border">
                {" "}
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    hidden
                    checked={inputValue.CigaretteRange == "1"}
                    onChange={(e) => {
                      setInputValue({
                        ...inputValue,
                        CigaretteRange: inputValue.CigaretteRange == 0 ? 1 : 0,
                      });
                    }}
                  />
                  <div
                    className={`w-11 h-6 ${
                      inputValue.CigaretteRange == 1
                        ? "bg-green-500"
                        : "bg-gray-300"
                    } rounded-full relative transition-colors duration-200 ease-in-out peer-checked:bg-blue-600`}
                  >
                    <span
                      className={`absolute left-1 top-1 w-4 h-4 ${
                        inputValue.CigaretteRange == 1 ? "bg-white" : "bg-white"
                      } rounded-full transition-transform duration-200 ease-in-out transform ${
                        inputValue.CigaretteRange == 1 ? "translate-x-full" : ""
                      }`}
                    />
                  </div>
                </label>
              </td>
              <td className="border">
                {" "}
                <textarea
                  name="Cigarette"
                  value={inputValue.Cigarette}
                  onChange={handleInputChange}
                  className="input w-full"
                  placeholder="Cigarette"
                  id=""
                ></textarea>
              </td>
            </tr>

            <tr className="border">
              <td className="border">Drinking Alcohol</td>
              <td className="border">
                {" "}
                <select
                  name="DrinkingAlcoholRange"
                  className="input w-full"
                  value={inputValue.DrinkingAlcoholRange}
                  onChange={handleInputChange}
                  id=""
                >
                  <option>Mild</option>
                  <option>Sever</option>
                  <option>Unspecified</option>
                </select>
              </td>
              <td className="border">
                {" "}
                <textarea
                  name="DrinkingAlcohol"
                  value={inputValue.DrinkingAlcohol}
                  onChange={handleInputChange}
                  className="input w-full"
                  placeholder="DrinkingAlcohol"
                  id=""
                ></textarea>
              </td>
            </tr>
          </tbody>
        </table>

        <div className="w-full mt-2">
          <textarea
            name="other"
            onChange={handleInputChange}
            value={inputValue.other}
            className="input w-full"
            placeholder="other"
            id=""
          ></textarea>
        </div>
        <div>
          <button
            className="btn-info duration-500 w-full"
            onClick={handleSubmit}
          >
            Submit
          </button>
        </div>
      </form>
      <div className="pb-4"></div>
    </div>
  );
};

export default MedicalHistory;

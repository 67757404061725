import { api } from "../../Api/api";
import Swal from "sweetalert2";

import { getToken } from "../login";
import { CREATE_OCCUPATION_FAIL, CREATE_OCCUPATION_REQUEST, CREATE_OCCUPATION_SUCCESS, FETCH_OCCUPATION_FAIL, FETCH_OCCUPATION_REQUEST, FETCH_OCCUPATION_SUCCESS, UPDATE_OCCUPATION_FAIL, UPDATE_OCCUPATION_REQUEST, UPDATE_OCCUPATION_SUCCESS } from "../../ActionType/OccupationTypes/OccupationTypes";


export const getOccupationAction = () => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
        type:FETCH_OCCUPATION_REQUEST,
    })
    try {
      const resp = await api.get("occupation", config);
      const { status, data } = resp;
    
      if (status == 200) {
        dispatch({
            type:FETCH_OCCUPATION_SUCCESS,
            payload:data,
        })
      }
    } catch (error) {
        dispatch({
            type:FETCH_OCCUPATION_FAIL,
            payload:[],
        })
        if(error.response.status==401){
          localStorage.removeItem("token")
          window.location.reload();
        }
    }
  };
export const createOccupationAction = (name) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type:CREATE_OCCUPATION_REQUEST,
})
  try {
    const resp = await api.post("occupation/create", {name}, config);
    const { status, data } = resp;
    dispatch({
      type:CREATE_OCCUPATION_SUCCESS,
  })
    if (status == 201) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        
        timer: 1500,
      });
      dispatch(getOccupationAction())
      dispatch(getOccupationAction())
    }
  } catch (error) {
    dispatch({
      type:CREATE_OCCUPATION_FAIL,
  })
    Swal.fire({
      icon: "error",

      title: "Check Your Request",
    //   showConfirmButton: false,
      timer: 1500,
    });
  }
};

export const updateOccupationAction = (id,name) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
      type:UPDATE_OCCUPATION_REQUEST,
  })
    try {
      const resp = await api.patch("occupation/update/"+id, {name}, config);
      const { status, data } = resp;
      dispatch({
        type:UPDATE_OCCUPATION_SUCCESS,
    })
      if (status == 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been Upadated",
          
          timer: 1500,
        });
        dispatch(getOccupationAction())
        dispatch(getOccupationAction())
      }
    } catch (error) {
      dispatch({
        type:UPDATE_OCCUPATION_FAIL,
    })
      Swal.fire({
        icon: "error",
  
        title: "Check Your Request",
      //   showConfirmButton: false,
        timer: 1500,
      });
    }
  };

import React from "react";

const Loading = () => {
  return (
  <div className="h-screen ">
    <div class="border-gray-300 h-20 w-20 animate-spin rounded-full border-8 border-t-teal-600 m-auto mt-72 " />
    </div>
  );
};

export default Loading;

import Swal from "sweetalert2";
import {
  CREATE_MEDICAL_HISTORY_FAIL,
  CREATE_MEDICAL_HISTORY_SUCCESS,
} from "../../ActionType/MedicalHistoryTypes/MedicalHistoryTypes";
import { api } from "../../Api/api";
import { loading } from "../Loading";
import { getToken } from "../login";
import { showPatientAction } from "../PatientActions/PatientAction";

export const createMedicalHistoryAction =
  (patient_id, types, ranges, notes) => async (dispatch) => {
    loading();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    console.log("types : ",types,"ranges : ",ranges,"notes : ",notes)
    try {
      const resp = await api.post(
        "medicalHistory/create",
        {
          patient_id,
          types,
          ranges,
          notes,
        },
        config
      );
      const { status, data } = resp;
        
      if (status == 201) {
        dispatch(showPatientAction(patient_id))
        dispatch(showPatientAction(patient_id))
        dispatch({
          type: CREATE_MEDICAL_HISTORY_SUCCESS,
          error: "",
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 422) {
        dispatch({
          type: CREATE_MEDICAL_HISTORY_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };

  
export const updateMedicalHistoryAction =
(id,patient_id, types, ranges, notes) => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.post(
      "medicalHistory/update",
      {
        id,
        patient_id,
        types,
        ranges,
        notes,
      },
      config
    );
    const { status, data } = resp;
 
    if (status == 201) {
      dispatch(showPatientAction(patient_id))
      dispatch(showPatientAction(patient_id))

      dispatch({
        type: CREATE_MEDICAL_HISTORY_SUCCESS,
        error: "",
      });
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been saved",
        timer: 1500,
      });
    }
  } catch (error) {
    console.log(error);
    if (error.response.status == 422) {
      dispatch({
        type: CREATE_MEDICAL_HISTORY_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

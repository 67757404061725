import Swal from "sweetalert2";
import { api } from "../../Api/api";
import { getToken } from "../login";
import {
  CREATE_PATIENT_CV_FAIL,
  CREATE_PATIENT_CV_SUCCESS,
  CREATE_PATIENT_FAIL,
  CREATE_PATIENT_SUCCESS,
  SHOW_PATIENT_FAIL,
  SHOW_PATIENT_REQUEST,
  SHOW_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
  UPDATE_PATIENT_SUCCESS,
} from "../../ActionType/PatientTypes/PatientTypes";
import { loading } from "../Loading";
import { createVisitAction } from "../VisitAction/visitAction";

export const createPatientAction =
  (
    doctor_id,
    occupation_id,
    fullname,
    phone,
    birth_date,
    gender,
    marital_status,
    address,
    blood,
    cvs,
    reset
  ) =>
  async (dispatch) => {

    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    try {
      const resp = await api.post(
        "patient/create",
        {
          doctor_id,
          fullname,
          phone,
          birth_date,
          gender,
          marital_status,
          address,
          blood,
          occupation_id,
        },
        config
      );
      const { status, data } = resp;

      if (status == 201) {
        reset()
        dispatch(createCvPatientAction(data.id, cvs));

        dispatch(createVisitAction(data.id));

        dispatch({
          type: CREATE_PATIENT_SUCCESS,
          error: "",
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 422) {
        dispatch({
          type: CREATE_PATIENT_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };

export const createCvPatientAction = (patient_id, cvs) => async (dispatch) => {
  // loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  const formData = new FormData();
  formData.append("patient_id", patient_id);
  cvs.map((cv) => {
    formData.append("cv[]", cv);
  });

  try {
    const resp = await api.post("patientCv/create", formData, config);

    const { status, data } = resp;

    if (status == 200) {
      dispatch(showPatientAction(patient_id))
      dispatch(showPatientAction(patient_id))
      dispatch({
        type: CREATE_PATIENT_CV_SUCCESS,
        error: "",
      });
    }
  } catch (error) {
    console.log(error);
    if (error.response.status == 422) {
      dispatch({
        type: CREATE_PATIENT_CV_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

export const showPatientAction = (id) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const response = await api.get("patient/show/" + id, config);
    const { status, data } = response;
   

    if (status == 200) {
     
      dispatch({
        type: SHOW_PATIENT_SUCCESS,
        payload: { patients: data.patient, visits: data.visits },
        erorr: "",
      });
    
    }
  } catch (error) {
    if(error.response.status==401){
      localStorage.removeItem("token")
      window.location.reload();
    }
    dispatch({
      type: SHOW_PATIENT_FAIL,
      payload: [],
      erorr: "",
    });
  }
};

export const updatePatientAction =
  (
    id,
    doctor_id,
    occupation_id,
    fullname,
    phone,
    birth_date,
    gender,
    marital_status,
    address,
    blood,
    cvs
    // reset
  ) =>
  async (dispatch) => {
    loading();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    try {
      const resp = await api.put(
        "patient/update/" + id,
        {
          doctor_id,
          fullname,
          phone,
          birth_date,
          gender,
          marital_status,
          address,
          blood,
          occupation_id,
        },
        config
      );
      const { status, data } = resp;
      if (status == 201) {
        dispatch(createCvPatientAction(id, cvs));
        dispatch(showPatientAction(id));
        dispatch(showPatientAction(id));
        dispatch({
          type: UPDATE_PATIENT_SUCCESS,
          error: "",
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Your work has been Updated",
          timer: 1500,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 422) {
        dispatch({
          type: UPDATE_PATIENT_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };


  
export const deletePatientCvAction =
(
  id,
  idImage,

  // reset
) =>
async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.delete(
      "patientCv/delete/" + idImage,
     
      config
    );
    const { status, data } = resp;
    if (status == 200) {
     
      dispatch(showPatientAction(id));
      dispatch(showPatientAction(id));
    
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been Deleted",
        timer: 1500,
      });
    }
  } catch (error) {

    if (error.response.status == 422) {
      dispatch({
        type: UPDATE_PATIENT_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};
import React, { useEffect, useState } from "react";
import {
  createCurrenllenusesAction,
  updateCurrenllenusesAction,
} from "../../../../Actions/CurrentllensesAction/CurrentLlensesAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { showPatientAction } from "../../../../Actions/PatientActions/PatientAction";
import { MdOutlineSaveAlt } from "react-icons/md";
const HistoryCurrentLenuses = ({ visits }) => {
  const showPatient = useSelector((state) => state.showPatient);

  const { loading, visits: getVisits } = showPatient;
  const [inputValue, setInputValue] = useState({});
  const { id: patient_id } = useParams();
  const dispatch = useDispatch();
  const [state, setState] = useState(null);
  useEffect(() => {
    let temp = {};
    getVisits
      .filter((filter) => filter.id == visits.id)
      .map((visit) => {
        setState(visit.state);
        if (visit.currentLlenuss.length > 0) {
          visit.currentLlenuss.map((medical) => {
            // temp[medical.type + "Range"] = medical.range;
            temp[medical.type] = medical.note;
            setInputValue({ ...temp });
          });
        } else {
          setInputValue({
            chiefComplain: "",
            examination: "",
            plan: "",
            diagnosis: "",
          });
        }
      });
  }, [visits, getVisits]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let ranges = [];
    let types = [];
    let notes = [];
    Object.entries(inputValue).forEach(([key, value]) => {
      if (key.toLocaleLowerCase().includes("range")) {
        ranges.push(value?.toString());
      } else {
        types.push(key?.toString());
        notes.push(value?.toString());
      }
    });
    let ids = [];

    if (visits.currentLlenuss.length > 0) {
      visits.currentLlenuss.map((currentLlenuss) => {
        ids.push(currentLlenuss.id);
      });
    }

    if (ids.length > 0) {
      dispatch(
        updateCurrenllenusesAction(ids, patient_id, visits.id, types, notes)
      );
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
    } else {
      dispatch(createCurrenllenusesAction(patient_id, visits.id, types, notes));
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div className="mt-3">
      <div className="head">
        <p>HISTORY OF CURRENT ILLNESS</p>
      </div>

      <form action="" className="flex flex-col mt-4">
        <div className="flex flex-col">
          <label htmlFor="">Chief Complain </label>
          <div>
            <textarea
              name="chiefComplain"
              value={inputValue.chiefComplain}
              onChange={handleInputChange}
              placeholder="Chief Complain"
              className="input mt-2 w-full"
              id=""
              disabled={state == 1 ? true : false}
            ></textarea>
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor="">Examination </label>
          <div>
            <textarea
              name="examination"
              value={inputValue.examination}
              onChange={handleInputChange}
              placeholder="Examination"
              className="input mt-2 w-full"
              id=""
              disabled={state == 1 ? true : false}
            ></textarea>
          </div>
        </div>
        <div className="flex flex-col">
          <label htmlFor="">Plan & follow up </label>
          <div>
            <textarea
              name="plan"
              value={inputValue.plan}
              onChange={handleInputChange}
              placeholder="Plan & follow up"
              className="input mt-2 w-full"
              id=""
              disabled={state == 1 ? true : false}
            ></textarea>
          </div>
        </div>

        <div className="flex flex-col">
          <label htmlFor=""> Diagnosis </label>
          <div>
            <textarea
              name="diagnosis"
              value={inputValue.diagnosis}
              onChange={handleInputChange}
              placeholder="Diagnosis"
              className="input mt-2 w-full"
              id=""
              disabled={state == 1 ? true : false}
            ></textarea>
          </div>
        </div>
        <div>
          <button
            className={
              state == 1
                ? "cursor-not-allowed btn-info mt-4 duration-500 flex gap-3 items-center"
                : "btn-info mt-4 duration-500 flex gap-3 items-center"
            }
            onClick={handleSubmit}
            disabled={state == 1 ? true : false}
          >
            Save
            <MdOutlineSaveAlt />
          </button>
        </div>
      </form>
      <div className="pb-4"></div>
    </div>
  );
};

export default HistoryCurrentLenuses;

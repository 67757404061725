export const CREATE_TREATMENT_REQUEST = "CREATE_TREATMENT_REQUEST";
export const CREATE_TREATMENT_SUCCESS = "CREATE_TREATMENT_SUCCESS";
export const CREATE_TREATMENT_FAIL = "CREATE_TREATMENT_FAIL";

export const FETCH_TREATMENT_REQUEST = "FETCH_TREATMENT_REQUEST";
export const FETCH_TREATMENT_SUCCESS = "FETCH_TREATMENT_SUCCESS";
export const FETCH_TREATMENT_FAIL = "FETCH_TREATMENT_FAIL";

export const SHOW_TREATMENT_REQUEST = "SHOW_TREATMENT_REQUEST";
export const SHOW_TREATMENT_SUCCESS = "SHOW_TREATMENT_SUCCESS";
export const SHOW_TREATMENT_FAIL = "SHOW_TREATMENT_FAIL";

export const UPDATE_TREATMENT_REQUEST = "UPDATE_TREATMENT_REQUEST";
export const UPDATE_TREATMENT_SUCCESS = "UPDATE_TREATMENT_SUCCESS";
export const UPDATE_TREATMENT_FAIL = "UPDATE_TREATMENT_FAIL";

export const CREATE_REPORT_REQUEST="CREATE_REPORT_REQUEST";
export const CREATE_REPORT_SUCCESS="CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAIL="CREATE_REPORT_FAIL";

export const FETCH_REPORT_REQUEST="FETCH_REPORT_REQUEST";
export const FETCH_REPORT_SUCCESS="FETCH_REPORT_SUCCESS";
export const FETCH_REPORT_FAIL="FETCH_REPORT_FAIL";

export const UPDATE_REPORT_REQUEST="UPDATE_REPORT_REQUEST";
export const UPDATE_REPORT_SUCCESS="UPDATE_REPORT_SUCCESS";
export const UPDATE_REPORT_FAIL="UPDATE_REPORT_FAIL";
import Swal from "sweetalert2";

import { api } from "../../Api/api";
import { loading } from "../Loading";
import { getToken } from "../login";
import {
  CREATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
  FETCH_GET_USER_AUTH_REQUEST,
  FETCH_GET_USER_AUTH_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_SUCCESS,
  SHOW_USER_FAIL,
  SHOW_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
} from "../../ActionType/UserTypes/UserTypes";

export const createUserAction =
  (name, email, password, admin_type, phone) => async (dispatch) => {
    loading();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    try {
      const resp = await api.post(
        "user/create",
        {
          name,
          email,
          password,
          admin_type,
          phone,
        },
        config
      );
      const { status, data } = resp;
      dispatch(getUserAction());
      dispatch(getUserAction());
      console.log(resp);
      if (status == 201) {
        dispatch({
          type: CREATE_USER_SUCCESS,
          error: "",
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 422) {
        dispatch({
          type: CREATE_USER_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };

export const getUserAction = () => async (dispatch) => {
  // loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.get("user", config);
    const { status, data } = resp;
    // dispatch(showPatientAction(42))
    if (status == 200) {
      dispatch({
        type: FETCH_USER_SUCCESS,
        payload: data,
        error: "",
      });
    }
  } catch (error) {
    if(error.response.status==401){
      localStorage.removeItem("token")
      window.location.reload();
    }
    if (error.response.status == 422) {
      dispatch({
        type: FETCH_USER_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

export const deleteUserAction = (id) => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.delete("user/delete/" + id, config);
    const { status, data } = resp;

    if (status == 200) {
      dispatch(getUserAction());
      dispatch(getUserAction());
      dispatch({
        type: DELETE_USER_SUCCESS,
        error: "",
      });
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been Deleted",
        timer: 1500,
      });
    }
  } catch (error) {
    //   console.log(error);
    if (error.response.status == 422) {
      dispatch({
        type: DELETE_USER_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

export const showUserAction = (id) => async (dispatch) => {
  // loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.get("user/show/" + id, config);
    const { status, data } = resp;
    // console.log("dsf : ",data);
    if (status == 200) {
      dispatch({
        type: SHOW_USER_SUCCESS,
        payload: data,
        error: "",
      });
    }
  } catch (error) {
    if(error.response.status==401){
      localStorage.removeItem("token")
      window.location.reload();
    }
    if (error.response.status == 422) {
      dispatch({
        type: SHOW_USER_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};
export const getUserAuthAction = () => async (dispatch) => {
  // loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.get("user/getUserAuth", config);
    const { status, data } = resp;
    
    if (status == 200) {
      dispatch({
        type: FETCH_GET_USER_AUTH_SUCCESS,
        payload: data,
        error: "",
      });
    }
  } catch (error) {
    if(error.response.status==401){
      localStorage.removeItem("token")
      window.location.reload();
    }
    if (error.response.status == 422) {
      dispatch({
        type: SHOW_USER_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};
export const updateUserAction =
  (id, name, email, password, admin_type, phone) => async (dispatch) => {
    // loading();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    try {
      const resp = await api.put(
        "user/update/" + id,
        { name, email, password, admin_type, phone },
        config
      );
      const { status, data } = resp;

      if (status == 200) {
        dispatch({
          type: UPDATE_USER_SUCCESS,
          payload: data,
          error: "",
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Your work has been Updated",
          timer: 1500,
        });
      }
    } catch (error) {
      if (error.response.status == 422) {
        dispatch({
          type: UPDATE_USER_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };

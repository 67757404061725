import Swal from "sweetalert2";
import { api } from "../../Api/api";
import { getToken } from "../login";

import { loading } from "../Loading";
import {
  CREATE_VISIT_FAIL,
  CREATE_VISIT_SUCCESS,
  FETCH_TOTAL_FAIL,
  FETCH_TOTAL_REQUEST,
  FETCH_TOTAL_SUCCESS,
  FETCH_VISIT_FAIL,
  FETCH_VISIT_REQUEST,
  FETCH_VISIT_SUCCESS,
} from "../../ActionType/VisitTypes/visitTypes";

export const getVisitAction =
  (page, perPage, fullname, phone, code, from, to,order) => async (dispatch) => {

    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      params: {
        page,
        perPage,
        fullname,
        phone,
        code,
        from,
        to,
        order
      },
    };
    dispatch({
      type: FETCH_VISIT_REQUEST,
    });
    try {
      const resp = await api.get("visit", config);
      const { status, data } = resp;

      if (status == 200) {
        dispatch({
          type: FETCH_VISIT_SUCCESS,
          payload: { data: data.visits, total: data.total },

          error: "",
        });
      }
    } catch (error) {
      if (error.response.status == 401) {
        localStorage.removeItem("token");
        window.location.reload();
      }
      if (error.response.status == 422) {
        dispatch({
          type: FETCH_VISIT_FAIL,
          // error: error.response.data.errors,
          // payload: [],
        });
      }
    }
  };

export const getTotalAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  dispatch({
    type: FETCH_TOTAL_REQUEST,
  });
  try {
    const resp = await api.get("visit/getTotal", config);
    const { status, data } = resp;

    if (status == 200) {
      dispatch({
        type: FETCH_TOTAL_SUCCESS,
        payload: data,

        error: "",
      });
    }
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("token");
      window.location.reload();
    }
    if (error.response.status == 422) {
      dispatch({
        type: FETCH_TOTAL_FAIL,
        // error: error.response.data.errors,
        // payload: [],
      });
    }
  }
};

export const createVisitAction = (patient_id) => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  try {
    const resp = await api.post("visit/create", { patient_id }, config);
    const { status, data } = resp;

    if (status == 200) {
      dispatch(getVisitAction(1, 10, "", "", "", "", "","desc"));
      dispatch(getVisitAction(1, 10, "", "", "", "", "","desc"));

      dispatch({
        type: CREATE_VISIT_SUCCESS,
        error: "",
      });
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been saved",
        timer: 1500,
      });
    }
  } catch (error) {
    if (error.response.status == 422) {
      dispatch({
        type: CREATE_VISIT_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

export const createVisitCvAction = (visit_id, cvs) => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  const formData = new FormData();

  formData.append("visit_id", visit_id);
  cvs.map((cv) => {
    formData.append("cv[]", cv);
  });

  try {
    const resp = await api.post("visit/cv/create", formData, config);
    const { status, data } = resp;
    console.log(resp)
    if (status == 200) {
      dispatch({
        type: CREATE_VISIT_SUCCESS,
        error: "",
      });
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been saved",
        timer: 1500,
      });
    }
  } catch (error) {
    console.log(error);
    if (error.response.status == 422) {
      dispatch({
        type: CREATE_VISIT_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

export const deleteVisitCvAction = (id) => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.delete("visit/cv/delete/" + id, config);
    const { status, data } = resp;

    if (status == 200) {
      dispatch({
        type: CREATE_VISIT_SUCCESS,
        error: "",
      });
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been Deleted",
        timer: 1500,
      });
    }
  } catch (error) {
    if (error.response.status == 422) {
      dispatch({
        type: CREATE_VISIT_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

export const deleteVisitAction = (id) => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.delete("visit/delete/" + id, config);
    const { status, data } = resp;

    if (status == 200) {
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been Deleted",
        timer: 1500,
      });
    }
  } catch (error) {
    if (error.response.status == 422) {
      dispatch({
        type: CREATE_VISIT_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

export const saveCompleteVisitAction = (id, state) => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.put("visit/saveComplete/" + id, { state }, config);
    const { status, data } = resp;

    if (status == 200) {
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been Updated",
        timer: 1500,
      });
    }
  } catch (error) {
    if (error.response.status == 422) {
      dispatch({
        type: CREATE_VISIT_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

export const savePriceVisitAction = (id, price) => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.put("visit/savePrice/" + id, { price }, config);
    const { status, data } = resp;

    if (status == 200) {
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been Updated",
        timer: 1500,
      });
    }
  } catch (error) {
    if (error.response.status == 422) {
      dispatch({
        type: CREATE_VISIT_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

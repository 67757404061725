import axios from "axios";
const APPURL = "https://test.medicalitt.info/app/api/v1/";
export const api = axios.create({
  // baseURL:"http://127.0.0.1:8000/api/v1/",
  baseURL: APPURL,
});

export const loginApi = axios.create({
  // baseURL:"http://127.0.0.1:8000/api/v1/",
  baseURL: APPURL,
  withCredentials: true,
});

import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
} from "../ActionType/loginTypes";

export const loginReducer = (
  state = { loading: true, isLoggedIn: false, userInfo: [], error: "" },
  action
) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        isLoggedIn: true,
        userInfo: action.payload,
        error: "",
      };
    case USER_LOGIN_FAIL:
      return {
        loading: false,
        isLoggedIn: false,
        userInfo: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const CREATE_MEDICAL_HISTORY_REQUEST="CREATE_MEDICAL_HISTORY_REQUEST";
export const CREATE_MEDICAL_HISTORY_SUCCESS="CREATE_MEDICAL_HISTORY_SUCCESS";
export const CREATE_MEDICAL_HISTORY_FAIL="CREATE_MEDICAL_HISTORY_FAIL";

export const FETCH_MEDICAL_HISTORY_REQUEST="FETCH_MEDICAL_HISTORY_REQUEST";
export const FETCH_MEDICAL_HISTORY_SUCCESS="FETCH_MEDICAL_HISTORY_SUCCESS";
export const FETCH_MEDICAL_HISTORY_FAIL="FETCH_MEDICAL_HISTORY_FAIL";

export const UPDATE_MEDICAL_HISTORY_REQUEST="UPDATE_MEDICAL_HISTORY_REQUEST";
export const UPDATE_MEDICAL_HISTORY_SUCCESS="UPDATE_MEDICAL_HISTORY_SUCCESS";
export const UPDATE_MEDICAL_HISTORY_FAIL="UPDATE_MEDICAL_HISTORY_FAIL";


import React, { useEffect, useState } from "react";

import DoctorModal from "../../Modal/DoctorModal/DoctorModal";
import { useDispatch, useSelector } from "react-redux";

import Loading from "../Loading";
import { getOccupationAction, updateOccupationAction } from "../../Actions/OccupationAction/OccupationAction";
import { MdOutlineSensorOccupied } from "react-icons/md";
import OccupationModal from "../../Modal/OccupationModal/OccupationModal";
import { FaEdit } from "react-icons/fa";

const Occupation = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getOccupation = useSelector((state) => state.getOccupation);
  const dispatch = useDispatch();
  const { occupations, loading } = getOccupation;
  const [indexx, setIndexx] = useState(null);
  const [name, setName] = useState(null);
  useEffect(() => {
    dispatch(getOccupationAction());
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleUpdate = (id) => {
    if(name=="" || name==null){
        return;
    }
    dispatch(updateOccupationAction(id, name));
  };
  const handleFoucsInput = (id) => {
    handleUpdate(id);
    setIndexx(null);
    setName(null);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="bg-white lg:w-[95%] mt-2  m-auto rounded-sm p-2">
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <p className="text-teal-500">
                <MdOutlineSensorOccupied />
              </p>
              <p>Occupation</p>
            </div>
            <div>
              <button className="btn-info" onClick={openModal}>
                Add Occupation
              </button>
            </div>
          </div>
          <div className="parent-table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {occupations?.map((occupation, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      {index == indexx ? (
                        <td>
                          <input
                            type="text"
                            onBlur={() => handleFoucsInput(occupation.id)}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="input w-full"
                            placeholder="Name"
                          />
                        </td>
                      ) : (
                        <td>{occupation.name}</td>
                      )}
                      <td>
                        <button
                          onClick={() => {
                            setIndexx(index);
                            setName(occupation.name);
                          }}
                          className="btn-primary"
                        >
                          <div className="flex gap-2 items-center">
                            <p>Edit</p>
                            <div><FaEdit /></div>
                          </div>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <OccupationModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
      )}
    </>
  );
};

export default Occupation;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  createUserAction,
  showUserAction,
  updateUserAction,
} from "../../Actions/UserAction/UserAction";
import { useParams } from "react-router-dom";

const UpdateUser = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const showUsers=useSelector(state=>state.showUsers);
  const {users}=showUsers;
  useEffect(() => {
    dispatch(showUserAction(id));
  }, []);
  const [formData, setFormData] = useState({
    id:"",
    fullname: "",
    email: "",
    password: "",
    retypePassword: "",
    phoneNumber: "123",
    adminType: "",
  });

  useEffect(()=>{
    setFormData({
        ...formData,
        id:users.id,
        fullname:users.name,
        email:users.email,
        phoneNumber:users.phone,
        adminType:users.admin_type,
        
    })
  },[users])

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation
    const errors = {};
    if (!formData.fullname.trim()) {
      errors.fullname = "Full Name is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is invalid";
    }
    if (!formData.password) {
      errors.password = "Password is required";
    }
    if (formData.password !== formData.retypePassword) {
      errors.retypePassword = "Passwords do not match";
    }
    if (!formData.phoneNumber.trim()) {
      errors.phoneNumber = "Phone Number is required";
    }

    if (Object.keys(errors).length === 0) {
      dispatch(
        updateUserAction(
            formData.id,
          formData.fullname,
          formData.email,
          formData.password,
          formData.adminType,
          formData.phoneNumber
        )
      );
 
    } else {
      setErrors(errors);
    }
  };

  return (
    <div className="max-w-md mx-auto mt-8 bg-white p-8 rounded shadow-md">
      <h2 className="text-lg font-semibold mb-4">Update User</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="fullname" className="block mb-1">
            Full Name:
          </label>
          <input
            type="text"
            id="fullname"
            name="fullname"
            value={formData.fullname}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
          />
          {errors.fullname && (
            <p className="text-red-500 mt-1">{errors.fullname}</p>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="email" className="block mb-1">
            Email:
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
          />
          {errors.email && <p className="text-red-500 mt-1">{errors.email}</p>}
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-1">
            Password:
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
          />
          {errors.password && (
            <p className="text-red-500 mt-1">{errors.password}</p>
          )}
        </div>
        <div className="mb-4">
          <label htmlFor="retypePassword" className="block mb-1">
            Retype Password:
          </label>
          <input
            type="password"
            id="retypePassword"
            name="retypePassword"
            value={formData.retypePassword}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
          />
          {errors.retypePassword && (
            <p className="text-red-500 mt-1">{errors.retypePassword}</p>
          )}
        </div>
        {/* <div className="mb-4">
          <label htmlFor="phoneNumber" className="block mb-1">
            Phone Number:
          </label>
          <input
            type="text"
            id="phoneNumber"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
          />
          {errors.phoneNumber && (
            <p className="text-red-500 mt-1">{errors.phoneNumber}</p>
          )}
        </div> */}
        <div className="mb-4">
          <label htmlFor="adminType" className="block mb-1">
            Admin Type:
          </label>
          <select
            id="adminType"
            name="adminType"
            value={formData.adminType}
            onChange={handleChange}
            className="w-full border rounded px-3 py-2"
          >
            <option value="">Select Admin Type</option>
            <option value={1}>Admin</option>
            <option value={0}>Staff</option>
          </select>
        </div>
        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default UpdateUser;

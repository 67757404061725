export const CREATE_INVESTIGATION_REQUEST="CREATE_INVESTIGATION_REQUEST";
export const CREATE_INVESTIGATION_SUCCESS="CREATE_INVESTIGATION_SUCCESS";
export const CREATE_INVESTIGATION_FAIL="CREATE_INVESTIGATION_FAIL";

export const FETCH_INVESTIGATION_REQUEST="FETCH_INVESTIGATION_REQUEST";
export const FETCH_INVESTIGATION_SUCCESS="FETCH_INVESTIGATION_SUCCESS";
export const FETCH_INVESTIGATION_FAIL="FETCH_INVESTIGATION_FAIL";

export const UPDATE_INVESTIGATION_REQUEST="UPDATE_INVESTIGATION_REQUEST";
export const UPDATE_INVESTIGATION_SUCCESS="UPDATE_INVESTIGATION_SUCCESS";
export const UPDATE_INVESTIGATION_FAIL="UPDATE_INVESTIGATION_FAIL";


import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createInvestigationAction,
  updateInvestigationAction,
} from "../../../../Actions/InvestigationAction/InvestigationAction";
import { MdOutlineSaveAlt } from "react-icons/md";
import { showPatientAction } from "../../../../Actions/PatientActions/PatientAction";

const Investigation = ({ visits }) => {
  const [inputValue, setInputValue] = useState({});
  const showPatient = useSelector((state) => state.showPatient);
  const { loading, patients, visits: getVisits } = showPatient;
  const { id: patient_id } = useParams();
  const [state, setState] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    let temp = {};
    getVisits
      .filter((filter) => filter.id == visits.id)
      .map((visit) => {
        setState(visit.state);
        if (visit.investigation.length > 0) {
          visit?.investigation?.map((medical) => {
            // temp[medical.type + "Range"] = medical.range;
            temp[medical.type] = medical.result;
            setInputValue({ ...temp });
          });
        } else {
          setInputValue({
            t4: "",
            atop: "",
            tsh: "",
            d3: "",
          });
        }
      });
  }, [getVisits, visits]);

  const handleSubmit = (event) => {
    event.preventDefault();

    let ranges = [];
    let types = [];
    let results = [];
    Object.entries(inputValue).forEach(([key, value]) => {
      if (key.toLocaleLowerCase().includes("range")) {
        ranges.push(value?.toString());
      } else {
        types.push(key?.toString());
        results.push(value?.toString());
      }
    });
    let ids = [];

    if (visits?.investigation?.length > 0) {
      visits?.investigation.map((investigation) => {
        ids.push(investigation.id);
      });
    }

    if (ids.length > 0) {
      dispatch(updateInvestigationAction(ids, visits?.id, types, results));
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
    } else {
      dispatch(createInvestigationAction(visits?.id, types, results));
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
      dispatch(showPatientAction(patient_id));
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <div className="mt-3">
      <div className=" bg-indigo-500 p-2 text-white rounded-sm">
        <p>INVESTIGATION</p>
      </div>

      <form action="" className="">
        <div className="grid lg:grid-cols-4 gap-3">
          <div className="flex flex-col">
            <label htmlFor="">T4 </label>
            <div>
              <input
                name="t4"
                type="number"
                value={inputValue.t4}
                onChange={handleInputChange}
                placeholder="t4"
                className="input mt-2 w-full"
                id=""
                disabled={state == 1 ? true : false}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="">TSH </label>
            <div>
              <input
                name="tsh"
                type="number"
                value={inputValue.tsh}
                onChange={handleInputChange}
                placeholder="TSH"
                className="input mt-2 w-full"
                id=""
                disabled={state == 1 ? true : false}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="">D3 </label>
            <div>
              <input
                name="d3"
                type="number"
                value={inputValue.d3}
                onChange={handleInputChange}
                placeholder="D3"
                className="input mt-2 w-full border-gray-300 border-2"
                id=""
                disabled={state == 1 ? true : false}
              />
            </div>
          </div>

          <div className="flex flex-col">
            <label htmlFor="">ATPO </label>
            <div>
              <input
                name="atop"
                type="number"
                value={inputValue.atop}
                onChange={handleInputChange}
                placeholder="ATPO"
                className="input mt-2 w-full"
                id=""
                disabled={state == 1 ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="mt-4">
          <hr />
          <div className="flex flex-col">
            <label htmlFor="">Other </label>
            <div>
              <textarea
                name="other"
                type="number"
                value={inputValue.other}
                onChange={handleInputChange}
                placeholder="Other"
                className="input mt-2 w-full"
                id=""
                disabled={state == 1 ? true : false}
              >
                {" "}
              </textarea>
            </div>
          </div>
        </div>
        <div>
          <button
            className={
              state == 1
                ? "cursor-not-allowed btn-info mt-4 duration-500 flex gap-3 items-center"
                : "btn-info mt-4 duration-500 flex gap-3 items-center"
            }
            onClick={handleSubmit}
            disabled={state == 1 ? true : false}
          >
            Save
            <MdOutlineSaveAlt />
          </button>
        </div>
      </form>
      <div className="pb-4"></div>
    </div>
  );
};

export default Investigation;

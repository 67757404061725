import React, { useEffect, useState } from "react";


import { useDispatch, useSelector } from "react-redux";

import Loading from "../Loading";


import { CiPill } from "react-icons/ci";
import { getDrugAction, updateDrugAction } from "../../Actions/DrugAction/DrugAction";
import DrugModal from "../../Modal/DrugModal/DrugModal";
import { FaEdit } from "react-icons/fa";

const Drug = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const getDrug = useSelector((state) => state.getDrug);
  const dispatch = useDispatch();
  const { drugs, loading } = getDrug;
  const [indexx, setIndexx] = useState(null);
  const [name, setName] = useState(null);
  useEffect(() => {
    dispatch(getDrugAction());
  }, []);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleUpdate = (id) => {
    if(name=="" || name==null){
        return;
    }
    dispatch(updateDrugAction(id, name));
  };
  const handleFoucsInput = (id) => {
    handleUpdate(id);
    setIndexx(null);
    setName(null);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="bg-white lg:w-[95%] mt-2  m-auto rounded-sm p-2">
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <p className="text-teal-500">
              <CiPill />

              </p>
              <p>Drug</p>
            </div>
            <div>
              <button className="btn-info" onClick={openModal}>
                Add Drug
              </button>
            </div>
          </div>
          <div className="parent-table">
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {drugs?.map((drug, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      {index == indexx ? (
                        <td>
                          <input
                            type="text"
                            onBlur={() => handleFoucsInput(drug.id)}
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="input w-full"
                            placeholder="Name"
                          />
                        </td>
                      ) : (
                        <td>{drug.name}</td>
                      )}
                      <td>
                        <button
                          onClick={() => {
                            setIndexx(index);
                            setName(drug.name);
                          }}
                          className="btn-primary"
                        >
                          <div className="flex gap-2 items-center">
                            <p>Edit</p>
                            <div><FaEdit /></div>
                          </div>
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <DrugModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
      )}
    </>
  );
};

export default Drug;

import Swal from "sweetalert2";
import {
  CREATE_TREATMENT_FAIL,
  CREATE_TREATMENT_SUCCESS,
  FETCH_TREATMENT_FAIL,
  FETCH_TREATMENT_SUCCESS,
} from "../../ActionType/TreatmentTypes/TreatmentTypes";
import { api } from "../../Api/api";
import { loading } from "../Loading";
import { getToken } from "../login";

export const createTreatmentAction =
  (visit_id, drug_id, patient_id, note) => async (dispatch) => {
    loading();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    try {
      const resp = await api.post(
        "treatment/create",
        {
          drug_id,
          visit_id,
          patient_id,
          note,
        },
        config
      );
      const { status, data } = resp;
      dispatch(getTreatmentAction());
      dispatch(getTreatmentAction());
      if (status == 201) {
        dispatch({
          type: CREATE_TREATMENT_SUCCESS,
          error: "",
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 422) {
        dispatch({
          type: CREATE_TREATMENT_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };

export const getTreatmentAction = () => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.get(
      "treatment",
      config
    );
    const { status, data } = resp;
    // dispatch(showPatientAction(42))
    if (status == 200) {
      dispatch({
        type: FETCH_TREATMENT_SUCCESS,
        error: "",
      });
    }
  } catch (error) {
    if(error.response.status==401){
      localStorage.removeItem("token")
      window.location.reload();
    }
    if (error.response.status == 422) {
      dispatch({
        type: FETCH_TREATMENT_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

export const delteTreatmentAction = (id) => async (dispatch) => {
  loading();
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const resp = await api.delete("treatment/delete/" + id, config);
    const { status, data } = resp;
  
    if (status == 200) {
      dispatch({
        type: CREATE_TREATMENT_SUCCESS,
        error: "",
      });
      Swal.fire({
        icon: "success",
        title: "Good Job",
        text: "Your work has been Deleted",
        timer: 1500,
      });
    }
  } catch (error) {
    //   console.log(error);
    if (error.response.status == 422) {
      dispatch({
        type: CREATE_TREATMENT_FAIL,
        error: error.response.data.errors,
        payload: [],
      });
    }
  }
};

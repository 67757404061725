import { CREATE_OCCUPATION_FAIL, CREATE_OCCUPATION_REQUEST, CREATE_OCCUPATION_SUCCESS, FETCH_OCCUPATION_FAIL, FETCH_OCCUPATION_REQUEST, FETCH_OCCUPATION_SUCCESS, UPDATE_OCCUPATION_FAIL, UPDATE_OCCUPATION_REQUEST, UPDATE_OCCUPATION_SUCCESS } from "../../ActionType/OccupationTypes/OccupationTypes";

export const getOccupationReducer = (
  state = { loading: true, occupations: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_OCCUPATION_REQUEST:
      return {
        loading: true,
      };
    case FETCH_OCCUPATION_SUCCESS:
      return {
        loading: false,
        occupations: action.payload,
        error: "",
      };
    case FETCH_OCCUPATION_FAIL:
      return {
        loading: false,
        occupations: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const createOccupationReducer = (
    state = { loading: true, occupations: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_OCCUPATION_REQUEST:
        return {
          loading: true,
        };
      case CREATE_OCCUPATION_SUCCESS:
        return {
          loading: false,
          occupations: action.payload,
          error: "",
        };
      case CREATE_OCCUPATION_FAIL:
        return {
          loading: false,
          occupations: [],
          error: "Check Your Request",
        };
      default:
        return state;
    }
  };


  export const updateOccupationReducer = (
    state = { loading: true, occupations: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_OCCUPATION_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_OCCUPATION_SUCCESS:
        return {
          loading: false,
          occupations: action.payload,
          error: "",
        };
      case UPDATE_OCCUPATION_FAIL:
        return {
          loading: false,
          occupations: [],
          error: "Check Your Request",
        };
      default:
        return state;
    }
  };
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePatientCvAction,
  showPatientAction,
  updatePatientAction,
} from "../../../Actions/PatientActions/PatientAction";
import { Link, useParams } from "react-router-dom";
import { getOccupationAction } from "../../../Actions/OccupationAction/OccupationAction";
import { getDoctorAction } from "../../../Actions/DoctorAction/DoctorAction";
import { getAge } from "../../../Actions/getAge";

import Select from "react-select";

import { FaPlus, FaTrash } from "react-icons/fa6";

import { IoIosCloudUpload } from "react-icons/io";
import { AiFillFilePdf } from "react-icons/ai";

const ProfilePatients = ({ patients }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const initialState = {
    id: patients?.id,
    fullname: patients?.fullname,
    phone: patients?.phone,
    birthDate: patients?.birth_date,
    gender: {
      value: patients?.gender,
      label: patients?.gender,
    },

    martialStatus: {
      value: patients?.marital_status,
      label: patients?.marital_status,
    },

    doctor: {
      value: patients?.doctor?.id,
      label: patients?.doctor?.name,
    },
    occupation: {
      value: patients?.occupation?.id,
      label: patients?.occupation?.name,
    },
    address: patients?.address,
    blood: {
      label: patients?.blood,
      value: patients?.blood,
    },
  };
  useEffect(() => {
    setInputValue(initialState);
  }, [patients, dispatch]);

  const [inputValue, setInputValue] = useState(initialState);
  const [image, setImage] = useState([]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  };

  let status = [];
  status.push(
    {
      label: "Married",
      value: "Married",
    },
    {
      label: "Single",
      value: "Single",
    },
    {
      label: "Widow",
      value: "Widow",
    },
    {
      label: "Divorse",
      value: "Divorse",
    }
  );

  let gender = [];
  gender.push(
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    }
  );
  const getDoctor = useSelector((state) => state.getDoctor);
  const { doctors } = getDoctor;
  useEffect(() => {
    dispatch(getDoctorAction());
  }, []);
  const getOccupation = useSelector((state) => state.getOccupation);
  const { occupations } = getOccupation;
  useEffect(() => {
    dispatch(getOccupationAction());
  }, []);
  let occupation = [];
  occupations?.map((occup) => {
    occupation.push({
      value: occup.id,
      label: occup.name,
    });
  });
  let doctor = [];
  doctors?.map((doc) => {
    doctor.push({
      value: doc.id,
      label: doc.name,
    });
  });
  const bloodType = [
    {
      value: "A+",
      label: "A+",
    },
    {
      value: "A-",
      label: "A-",
    },
    {
      value: "B+",
      label: "B+",
    },
    {
      value: "B-",
      label: "B-",
    },
    {
      value: "O+",
      label: "O+",
    },
    {
      value: "O-",
      label: "O-",
    },
    {
      value: "AB+",
      label: "AB+",
    },
    {
      value: "AB-",
      label: "AB-",
    },
  ];

  const createPatient = useSelector((state) => state.createPatient);
  const { errors } = createPatient;

  const age = getAge(inputValue.birthDate);

  const hadnleAdd = () => {
    setImage([...image, ""]);
  };

  const handleRemoveInput = (index) => {
    const removeInput = [...image];
    removeInput.splice(index, 1);
    setImage(removeInput);
  };

  const handleImage = (e, index) => {
    const updatedImages = [...image];

    updatedImages[index] = e.target.files[0];
    setImage(updatedImages);
  };
  const handleUpdate = () => {
    dispatch(
      updatePatientAction(
        inputValue.id,
        inputValue.doctor.value,
        inputValue.occupation.value,
        inputValue.fullname,
        inputValue.phone,
        inputValue.birthDate,
        inputValue.gender.value,
        inputValue.martialStatus.value,
        inputValue.address,
        inputValue.blood.value,
        image
      )
    );
  };

  const getFileExtension = (url) => {
    const parts = url.split(".");
    return parts[parts.length - 1];
  };
  const handleDeleteImage = (idImage) => {
    dispatch(deletePatientCvAction(id, idImage));
  };
  return (
    <div className=" w-full">
      <div className="container ">
        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Full Name *
          </label>
          <div>
            <input
              type="text"
              className="input w-full"
              name="fullname"
              value={inputValue.fullname}
              onChange={handleInputChange}
              placeholder="Full Name"
            />
          </div>

          <div className="text-red-600">{errors && errors.fullname}</div>
        </div>

        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Phone Number *
          </label>
          <div>
            <input
              type="text"
              className="input w-full"
              name="phone"
              value={inputValue.phone}
              onChange={handleInputChange}
              placeholder="Phone"
              pattern="\d*"
              inputMode="numeric"
              onInput={(e) => {
                e.target.value = e.target.value.replace(/\D/g, "");
              }}
            />
          </div>
          <div className="text-red-600">{errors && errors.phone}</div>
        </div>

        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Birth Date *
          </label>
          <div>
            <input
              type="date"
              className="input w-full"
              name="birthDate"
              value={inputValue.birthDate}
              onChange={handleInputChange}
              placeholder="Birth Date"
            />
          </div>
          <div className="text-red-600">{errors && errors.birth_date}</div>
        </div>

        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Age *
          </label>
          <div>
            <input
              type="text"
              value={age}
              className="input w-full "
              disabled
              // onChange={handleInputChange}
              placeholder="Age"
            />
          </div>
        </div>

        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Gender *
          </label>
          <div>
            <Select
              options={gender}
              value={inputValue.gender}
              onChange={(selectOptions) => {
                setInputValue({
                  ...inputValue,
                  gender: {
                    value: selectOptions.value,
                    label: selectOptions.value,
                  },
                });
              }}
            />
          </div>
          <div className="text-red-600">{errors && errors.gender}</div>
        </div>

        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Marital Status *
          </label>
          <div>
            <Select
              // value={selectedOption}
              onChange={(selectOptions) => {
                console.log(selectOptions);
                setInputValue({
                  ...inputValue,
                  martialStatus: {
                    value: selectOptions.value,
                    label: selectOptions.label,
                  },
                });
              }}
              value={inputValue.martialStatus}
              options={status}
            />
          </div>
        </div>

        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Occupation *
          </label>
          <div>
            <Select
              value={inputValue.occupation}
              options={occupation}
              onChange={(selectOptions) => {
                setInputValue({
                  ...inputValue,
                  occupation: {
                    value: selectOptions.value,
                    label: selectOptions.label,
                  },
                });
              }}
            />
          </div>
        </div>

        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Doctor *
          </label>
          <div>
            <Select
              options={doctor}
              value={inputValue.doctor}
              onChange={(selectOptions) => {
                setInputValue({
                  ...inputValue,
                  doctor: {
                    value: selectOptions.value,
                    label: selectOptions.label,
                  },
                });
              }}
            />
          </div>
        </div>

        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Address *
          </label>
          <div>
            <input
              type="text"
              className="input w-full "
              name="address"
              value={inputValue.address}
              onChange={handleInputChange}
              placeholder="Address"
            />
          </div>
        </div>

        <div className="parent-input">
          <label htmlFor="" className="lbl">
            Blood Type *
          </label>
          <div>
            <Select
              options={bloodType}
              onChange={(selectOptions) => {
                setInputValue({
                  ...inputValue,
                  blood: {
                    value: selectOptions.value,
                    label: selectOptions.value,
                  },
                });
              }}
              value={inputValue.blood}
            />
          </div>
        </div>
      </div>
      <div className="px-3 w-full pb-4  flex justify-end">
        <button className="btn-info" onClick={hadnleAdd}>
          <FaPlus />
        </button>
      </div>

      {image.map((img, index) => {
        return (
          <div key={index} className="flex items-center w-full px-4 m-auto">
            <label htmlFor={`doc-${index}`} className="inputFile">
              <div className="file-container">
                <h4 className="text-base font-semibold text-center text-gray-700">
                  {img ? img.name : "Upload a file "}
                </h4>
                <div className="text-teal-600">
                  <IoIosCloudUpload />
                </div>
              </div>
              <input
                type="file"
                id={`doc-${index}`}
                onChange={(e) => handleImage(e, index)}
                name={`image_${index}`}
                hidden
              />
            </label>

            <button
              className="btn-danger py-2"
              onClick={() => handleRemoveInput(index)}
            >
              <FaTrash />
            </button>
          </div>
        );
      })}

      <button
        onClick={handleUpdate}
        className="btn-info w-5/12 m-auto flex justify-center mt-5"
      >
        Submit
      </button>
      <div className="pb-4"></div>
      <div className="parent-table w-8/12 m-auto">
        <table className="w-8/12">
          <thead>
            <tr>
              <th>#</th>
              <th>Image</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {patients?.patientCv?.map((cv, index) => {
              const fileExtension = getFileExtension(cv.cv); // Extract the file extension
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={cv.cv} target="_blank">
                      {fileExtension == "pdf" ? (
                        <p className="text-4xl text-red-800">
                          <AiFillFilePdf />
                        </p>
                      ) : (
                        <img
                          src={cv.cv}
                          className="w-20 object-cover"
                          alt="img"
                        />
                      )}
                    </Link>
                  </td>
                  <td>
                    <button
                      onClick={() => handleDeleteImage(cv.id)}
                      className="btn-danger"
                    >
                      <FaTrash />
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProfilePatients;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Loading from "../Loading";
// import { getVisitAction } from "../../Actions/VisitAction/visitAction";

import ReactPaginate from "react-paginate";
import { IoIosArrowBack } from "react-icons/io";
import { CiFilter } from "react-icons/ci";

import { RxCheck, RxCross1 } from "react-icons/rx";
// import { getToday, getTomorrow } from "../../Reducer/VisitReducer/VisitReducer";
import { useNavigate } from "react-router-dom";
import {
  createVisitAction,
  getVisitAction,
} from "../../../Actions/VisitAction/visitAction";

import Loading from "../../Loading";

const Search = () => {
  const [isOpenFitler, setIsOpenFilter] = useState(false);
  const [indexx, setIndexx] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();
  const getVisit = useSelector((state) => state.getVisit);
  const { visits, loading, total } = getVisit;
  useEffect(() => {
    dispatch(
      getVisitAction(
        page,
        perPage,
        searchParams.fullname,
        searchParams.phone,
        searchParams.code,
        searchParams.from,
        searchParams.to,
        "desc"
      )
    );
  }, [perPage]);

  const handleSearch = () => {
    dispatch(
      getVisitAction(
        page,
        perPage,
        searchParams.fullname,
        searchParams.phone,
        searchParams.code,
        searchParams.from,
        searchParams.to,
        "desc"
      )
    );
    setIsOpenFilter(false);
    setIndexx(null);
  };
  const handleClearSearch = (name) => {
    const updateSearchParams = { ...searchParams };
    updateSearchParams[name] = null;
    setSearchParams(updateSearchParams);
    dispatch(
      getVisitAction(
        page,
        perPage,
        updateSearchParams.fullname,
        updateSearchParams.phone,
        updateSearchParams.code,
        searchParams.from,
        searchParams.to,
        "desc"
      )
    );
    setIsOpenFilter(false);
    setIndexx(null);
  };

  const [searchParams, setSearchParams] = useState({
    code: "",
    fullname: "",
    phone: "",
    from: "",
    to: "",
  });
  const handleSearchParams = (event) => {
    const { value, name } = event.target;
    setSearchParams({
      ...searchParams,
      [name]: value,
    });
  };

  const onPageChange = (event) => {
    setPage(event.selected);
    dispatch(
      getVisitAction(
        event.selected + 1,
        perPage,
        searchParams.fullname,
        searchParams.phone,
        searchParams.code,
        searchParams.from,
        searchParams.to,
        "desc"
      )
    );
  };
  const th = [
    {
      name: "code",
      label: "Code",
    },
    {
      name: "fullname",
      label: "Full Name",
    },
    {
      name: "phone",
      label: "Phone",
    },
  ];
  const navigate = useNavigate();
  const handleNewVisit = (id) => {
    dispatch(createVisitAction(id));
  };
  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div className="bg-white lg:w-[95%]  mt-2  m-auto rounded-sm p-2">
          <div className="flex justify-between">
            <div className="flex items-center gap-2">
              <input
                type="date"
                className=" outline-none border focus:border-blue-400 p-1"
                name="from"
                onChange={handleSearchParams}
                value={searchParams.from}
              />
              <p>To</p>
              <input
                type="date"
                className=" outline-none border focus:border-blue-400 p-1"
                name="to"
                onChange={handleSearchParams}
                value={searchParams.to}
              />
              <button className="btn-info " onClick={handleSearch}>
                <CiFilter />
              </button>
            </div>
            <div></div>
          </div>

          <div className={isOpenFitler ? "" : "parent-table"}>
            <table>
              <thead className="coustm-thead">
                <tr>
                  <th className="coustm-th ">#</th>
                  {th.map((th, index) => {
                    return (
                      <th className="coustm-th relative " key={index}>
                        <div className="flex justify-between items-center">
                          <p>{th.label}</p>
                          <div
                            onClick={() => {
                              setIndexx(index);
                              setIsOpenFilter(!isOpenFitler);
                            }}
                            className={
                              index == indexx || searchParams[th.name]
                                ? "text-xl text-gray-200 bg-indigo-500/50 backdrop-blur-2xl py-2 cursor-pointer px-2"
                                : "text-xl text-gray-200 bg-white/30 backdrop-blur-2xl py-2 cursor-pointer px-2"
                            }
                          >
                            <CiFilter />
                          </div>
                        </div>
                        {isOpenFitler && index == indexx ? (
                          <div className="absolute top-8 left-0 backdrop-blur-xl w-full bg-black/30 p-2">
                            <input
                              type="text"
                              className="input w-full text-black"
                              placeholder={th.label}
                              name={th.name}
                              onChange={handleSearchParams}
                              value={searchParams[th.name]}
                            />
                            <div className="flex gap-2">
                              <button
                                className="btn-primary mt-2 font-bold px-0 "
                                onClick={handleSearch}
                              >
                                <RxCheck />
                              </button>
                              <button
                                onClick={() => {
                                  handleClearSearch(th.name);
                                }}
                                className="btn-danger mt-2 font-bold px-0 "
                              >
                                <RxCross1 />
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </th>
                    );
                  })}
                  <th className="coustm-th">Patient Cvs</th>
                  <th className="coustm-th">Visitations</th>
                  <th className="coustm-th">New action</th>
                </tr>
              </thead>
              <tbody className="border-2 border-black">
                {visits?.map((visit, index) => {
                  return (
                    <tr key={index} className="cursor-pointer hover:bg-gray-50">
                      <td
                        className="coustm-td"
                        onClick={() => navigate("/profile/visits/" + visit.id)}
                      >
                        {index + 1}
                      </td>
                      <td
                        className="coustm-td"
                        onClick={() => navigate("/profile/visits/" + visit.id)}
                      >
                        {visit.code}
                      </td>
                      <td
                        className="coustm-td"
                        onClick={() => navigate("/profile/visits/" + visit.id)}
                      >
                        {visit.fullname}
                      </td>
                      <td
                        onClick={() => navigate("/profile/visits/" + visit.id)}
                        className="coustm-td"
                      >
                        {visit.phone}
                      </td>
                      <td
                        onClick={() => navigate("/profile/visits/" + visit.id)}
                        className="coustm-td text-teal-700 text-lg underline text-center font-bold"
                      >
                        {visit.patient_cv_count}
                      </td>
                      <td
                        onClick={() => navigate("/profile/visits/" + visit.id)}
                        className="coustm-td text-teal-700 text-lg underline text-center font-bold"
                      >
                        {visit.visit_count}
                      </td>
                      <td className="coustm-td text-center">
                        <button
                          className="btn-info"
                          onClick={() => handleNewVisit(visit.id)}
                        >
                          New Visit
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="flex gap-3 items-center justify-end mt-2">
            <div className="mt-2 md:w-1/12">
              <select
                className="outline-none border-blue-400 rounded-sm border py-1 w-full"
                value={perPage}
                onChange={(e) => setPerPage(e.target.value)}
              >
                {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(
                  (perPage, index) => {
                    return (
                      <option value={perPage} key={index}>
                        {perPage}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
            <ReactPaginate
              breakLabel={<span className="break">...</span>}
              nextLabel={
                <p className="  arrow rotate-180">
                  <IoIosArrowBack />
                </p>
              }
              onPageChange={onPageChange}
              forcePage={page}
              pageRangeDisplayed={5}
              pageCount={total / perPage}
              previousLabel={
                <p className=" arrow">
                  <IoIosArrowBack />
                </p>
              }
              renderOnZeroPageCount={null}
              containerClassName="paginate-container"
              activeClassName="active"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;

import React from "react";

const TreatmentPrint = ({ tratment }) => {
  return (
    <div className="w-11/12 m-auto mt-4">
      <table>
        <thead className=" ">
          <tr className="">
            <th className=" ">#</th>
            <th className=" ">Drug</th>
            <th className=" ">Note</th>
          </tr>
        </thead>
        <tbody className="">
          
          {tratment?.map((drug, index) => {
            return (
              <tr className=" p-0">
                <td className=" w-[5%] p-1">{index + 1}</td>
                <td className=" w-[40%] p-1">{drug.drug.name}</td>
                <td className="w-[55%]  p-1">{drug.note}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TreatmentPrint;

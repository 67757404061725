import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import loginAction from "../../Actions/login";
const Login = () => {
  const dispatch = useDispatch();
  const loginReducer = useSelector((state) => state.loginReducer);
  const { loading, userInfo, isLoggedIn } = loginReducer;
  const [inputValue, setInputValue] = useState({
    username: "",
    password: "",
  });
  const navigate = useNavigate();
  function handleInputChange(event) {
    const { name, value } = event.target;
    setInputValue({
      ...inputValue,
      [name]: value,
    });
  }
  function handleSubmit(event) {
    event.preventDefault();
    dispatch(loginAction(inputValue.username, inputValue.password));
  }

  useEffect(() => {
    if (userInfo?.length > 0 || loading || isLoggedIn) {
      navigate("/");
    }
  }, [userInfo, loading, isLoggedIn]);
  return (
    <div className="parent-login">
      <div className="head-login">Login</div>

      <form action="" onSubmit={handleSubmit}>
        <div className="parent-input">
          <div>
            <label htmlFor="user" className="lbl">
              User Name *
            </label>
          </div>
          <input
            type="text"
            id="user"
            value={inputValue.username}
            onChange={handleInputChange}
            name="username"
            className="input"
            placeholder="User Name"
          />
        </div>

        <div className="parent-input mt-2">
          <div>
            <label htmlFor="pass" className="lbl">
              Password *
            </label>
          </div>
          <input
            type="password"
            id="pass"
            value={inputValue.password}
            onChange={handleInputChange}
            name="password"
            className="input"
            placeholder="Password"
          />
        </div>

        <button type="submit" className="btn-primary mt-2">
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showPatientAction } from "../../../Actions/PatientActions/PatientAction";
import { useParams } from "react-router-dom";
import { getOccupationAction } from "../../../Actions/OccupationAction/OccupationAction";
import { getDoctorAction } from "../../../Actions/DoctorAction/DoctorAction";

import Loading from "../../Loading";
import ProfilePatients from "./ProfilePatients";
import MedicalHistory from "./MedicalHistory/MedicalHistory";
import EachVisit from "./EachVisits/EachVisit";
import ChartPatient from "./EachVisits/Chart/ChartPatient";
import { getUserAuthAction } from "../../../Actions/UserAction/UserAction";

const ProfileVisits = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const showPatient = useSelector((state) => state.showPatient);
  const { loading, patients, visits } = showPatient;
  const getUsersAuth = useSelector((state) => state.getUsersAuth);
  const { user } = getUsersAuth;
  
  useEffect(() => {
    dispatch(showPatientAction(id));
    dispatch(getDoctorAction());
    dispatch(getOccupationAction());
    dispatch(getUserAuthAction());
  }, [loading]);

  const dynamicTabs = visits?.map((visit, index) => {
    return {
      id: visit?.id,
      label: visit?.created_at?.toString().split("T")[0],
      content: <EachVisit user={user} visits={visit} />,
    };
  });

  const tabs = [
    {
      id: -2,
      label: "Profile",
      content: <ProfilePatients patients={patients} />,
    },
    ...(user.admin_type === 1
      ? [
          {
            id: -1,
            label: "Medical History",
            content: (
              <MedicalHistory medicalHistory={patients?.medicalHistory} />
            ),
          },
          {
            id: 0,
            label: "Chart",
            content: <ChartPatient visits={visits} />,
          },
        ]
      : []),
    ...dynamicTabs,
  ];
  const [activeTab, setActiveTab] = useState(tabs[0]);

  // Handle loading state
  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {loading || visits == undefined || patients == undefined ? (
        <Loading />
      ) : (
        <div className="mt-2">
          <div className="flex justify-between w-[97%] m-auto bg-teal-600  text-white px-3 py-2 rounded-sm shadow-md">
            <p>{patients?.code}</p>
            <p>{patients?.fullname}</p>
            <p>{patients?.phone}</p>
            <p>{patients?.gender}</p>
          </div>
          <div className=" flex flex-col lg:flex lg:flex-row gap-4 m-auto bg-white  rounded-sm shadow-lg w-[97%]">
            <div className="lg:w-2/12 w-11/12 m-auto  lg:ml-4 mt-4">
              <div className="bg-gray-100  rounded-sm ">
                <div className="flex flex-col">
                  {tabs.map((tab) => (
                    <div className="w-full border rounded-sm" key={tab.id}>
                      <button
                        className={`px-4 py-2 ${
                          activeTab.id === tab.id
                            ? "bg-indigo-600 duration-500 rounded-sm  text-white w-full"
                            : "text-indigo-600 duration-500 rounded-sm w-full"
                        } hover:bg-indigo-700 duration-500 rounded-sm hover:text-white w-full`}
                        onClick={() => setActiveTab(tab)}
                      >
                        {tab.label}
                      </button>
                      <hr />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="mt-4 w-full">
              {/* Conditional rendering of ProfilePatients component */}
              {activeTab.content.type === ProfilePatients && (
                <ProfilePatients patients={patients} />
              )}
              {/* Render other tabs */}
              {activeTab.content.type !== ProfilePatients && activeTab.content}
            </div>
          </div>
        </div>
      )}
      <div className="pb-10"></div>
    </>
  );
};

export default ProfileVisits;

import { api } from "../../Api/api";
import Swal from "sweetalert2";

import { getToken } from "../login";
import { FETCH_DOCTOR_FAIL, FETCH_DOCTOR_REQUEST, FETCH_DOCTOR_SUCCESS } from "../../ActionType/DoctorTypes/DoctorTypes";


export const getDoctorAction = () => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch({
        type:FETCH_DOCTOR_REQUEST,
    })
    try {
      const resp = await api.get("doctor", config);
      const { status, data } = resp;
    //   console.log(resp);
      if (status == 200) {
        dispatch({
            type:FETCH_DOCTOR_SUCCESS,
            payload:data,
        })
      }
    } catch (error) {
      if(error.response.status==401){
        localStorage.removeItem("token")
        window.location.reload();
      }
        dispatch({
            type:FETCH_DOCTOR_FAIL,
            payload:[],
        })
      
    }
  };
export const createDoctorAction = (name) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };
  try {
    const resp = await api.post("doctor/create", {name}, config);
    const { status, data } = resp;
    // console.log(resp);
    if (status == 201) {
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Your work has been saved",
        
        timer: 1500,
      });
      dispatch(getDoctorAction())
      dispatch(getDoctorAction())
    }
  } catch (error) {
    console.log(error)
    Swal.fire({
      icon: "error",

      title: "Check Your Request",
    //   showConfirmButton: false,
      timer: 1500,
    });
  }
};

export const updateDoctorAction = (id,name) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    try {
      const resp = await api.patch("doctor/update/"+id, {name}, config);
      const { status, data } = resp;
      // console.log(resp);
      if (status == 200) {
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been Upadated",
          
          timer: 1500,
        });
        dispatch(getDoctorAction())
        dispatch(getDoctorAction())
      }
    } catch (error) {
      console.log(error)
      Swal.fire({
        icon: "error",
  
        title: "Check Your Request",
      //   showConfirmButton: false,
        timer: 1500,
      });
    }
  };

import {
  CREATE_PATIENT_FAIL,
  CREATE_PATIENT_REQUEST,
  CREATE_PATIENT_SUCCESS,
  FETCH_PATIENT_FAIL,
  FETCH_PATIENT_REQUEST,
  FETCH_PATIENT_SUCCESS,
  SHOW_PATIENT_FAIL,
  SHOW_PATIENT_REQUEST,
  SHOW_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
  UPDATE_PATIENT_REQUEST,
  UPDATE_PATIENT_SUCCESS,
} from "../../ActionType/PatientTypes/PatientTypes";

export const getPatientReducer = (
  state = { loading: true, patients: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_PATIENT_SUCCESS:
      return {
        loading: false,
        patients: action.payload,
        error: "",
      };
    case FETCH_PATIENT_FAIL:
      return {
        loading: false,
        patients: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const showPatientReducer = (
  state = { loading: true, patients: [], visits: [], error: "" },
  action
) => {
  switch (action.type) {
    case SHOW_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case SHOW_PATIENT_SUCCESS:
      return {
        loading: false,
        patients: action.payload.patients,
        visits: action.payload.visits,
        error: "",
      };
    case SHOW_PATIENT_FAIL:
      return {
        loading: false,
        patients: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const createPatientReducer = (
  state = { loading: true, patients: [], errors: "" },
  action
) => {
  // console.log(action.error)
  switch (action.type) {
    case CREATE_PATIENT_REQUEST:
      return {
        loading: true,
      };

    case CREATE_PATIENT_SUCCESS:
      return {
        loading: false,
        patients: action.payload,
        error: "",
      };
    case CREATE_PATIENT_FAIL:
      return {
        loading: false,
        patients: [],
        errors: action.error,
      };
    default:
      return state;
  }
};

export const updatePatientReducer = (
  state = { loading: true, patients: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_PATIENT_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_PATIENT_SUCCESS:
      return {
        loading: false,
        patients: action.payload,
        error: "",
      };
    case UPDATE_PATIENT_FAIL:
      return {
        loading: false,
        patients: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

import {
    CREATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  FETCH_GET_USER_AUTH_FAIL,
  FETCH_GET_USER_AUTH_REQUEST,
  FETCH_GET_USER_AUTH_SUCCESS,
  FETCH_USER_FAIL,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  SHOW_USER_FAIL,
  SHOW_USER_REQUEST,
  SHOW_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
} from "../../ActionType/UserTypes/UserTypes";

export const getUsersReducer = (
  state = { loading: true, users: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return {
        loading: true,
      };
    case FETCH_USER_SUCCESS:
      return {
        loading: false,
        users: action.payload,
        error: "",
      };
    case FETCH_USER_FAIL:
      return {
        loading: false,
        users: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const getUsersAuthReducer = (
  state = { loading: true, user: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_GET_USER_AUTH_REQUEST:
      return {
        loading: true,
      };
    case FETCH_GET_USER_AUTH_SUCCESS:
      return {
        loading: false,
        user: action.payload,
        error: "",
      };
    case FETCH_GET_USER_AUTH_FAIL:
      return {
        loading: false,
        users: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};

export const createUsersReducer = (
    state = { loading: true, users: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case CREATE_USER_FAIL:
        return {
          loading: true,
        };
      case CREATE_USER_SUCCESS:
        return {
          loading: false,
          users: action.payload,
          error: "",
        };
      case CREATE_USER_FAIL:
        return {
          loading: false,
          users: [],
          error: "Check Your Request",
        };
      default:
        return state;
    }
  };

  export const showUsersReducer = (
    state = { loading: true, users: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case SHOW_USER_REQUEST:
        return {
          loading: true,
        };
      case SHOW_USER_SUCCESS:
        return {
          loading: false,
          users: action.payload,
          error: "",
        };
      case SHOW_USER_FAIL:
        return {
          loading: false,
          users: [],
          error: "Check Your Request",
        };
      default:
        return state;
    }
  };

  export const deleteUsersReducer = (
    state = { loading: true, users: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case DELETE_USER_REQUEST:
        return {
          loading: true,
        };
      case DELETE_USER_SUCCESS:
        return {
          loading: false,
          users: action.payload,
          error: "",
        };
      case DELETE_USER_FAIL:
        return {
          loading: false,
          users: [],
          error: "Check Your Request",
        };
      default:
        return state;
    }
  };

  export const updateUsersReducer = (
    state = { loading: true, users: [], error: "" },
    action
  ) => {
    switch (action.type) {
      case UPDATE_USER_REQUEST:
        return {
          loading: true,
        };
      case UPDATE_USER_SUCCESS:
        return {
          loading: false,
          users: action.payload,
          error: "",
        };
      case UPDATE_USER_FAIL:
        return {
          loading: false,
          users: [],
          error: "Check Your Request",
        };
      default:
        return state;
    }
  };
  

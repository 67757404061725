import Swal from "sweetalert2";

import { api } from "../../Api/api";
import { loading } from "../Loading";
import { getToken } from "../login";
import {
  CREATE_CURRENLLENUSES_FAIL,
  CREATE_CURRENLLENUSES_SUCCESS,
  UPDATE_CURRENLLENUSES_FAIL,
  UPDATE_CURRENLLENUSES_SUCCESS,
  UPDATE_CURRENLLENUSES_VISIT_FAIL,
  UPDATE_CURRENLLENUSES_VISIT_SUCCESS,
} from "../../ActionType/CurrentllensesTypes/CurrentllensesTypes";
import { showPatientAction } from "../PatientActions/PatientAction";

export const createCurrenllenusesAction =
  (patient_id, visit_id, types, notes) => async (dispatch) => {
    loading();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };

    try {
      const resp = await api.post(
        "currentllenuss/create",
        {
          visit_id,
          types,
          notes,
        },
        config
      );
      const { status, data } = resp;

      if (status == 201) {
        let ids = [];
        data.map((item) => {
          ids.push(item.id);
        });
        dispatch(createCurrenllenusesVisitAction(visit_id, ids));
        dispatch(showPatientAction(patient_id));
        dispatch(showPatientAction(patient_id));

        dispatch({
          type: CREATE_CURRENLLENUSES_SUCCESS,
          error: "",
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      if(error.response.status==401){
        localStorage.removeItem("token")
        window.location.reload();
      }
      if (error.response.status == 422) {
        dispatch({
          type: CREATE_CURRENLLENUSES_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };

export const updateCurrenllenusesAction =
  (id, patient_id, visit_id, types, notes) => async (dispatch) => {
    loading();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    dispatch(showPatientAction(patient_id));
    dispatch(showPatientAction(patient_id));
    try {
      const resp = await api.post(
        "currentllenuss/update",
        {
          id,
          visit_id,
          types,
          notes,
        },
        config
      );
      const { status, data } = resp;

      if (status == 201) {
        let ids = [];
        data.map((item) => {
          ids.push(item.id);
        });
        dispatch(createCurrenllenusesVisitAction(visit_id, ids));

        dispatch({
          type: UPDATE_CURRENLLENUSES_SUCCESS,
          error: "",
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      // console.log(error);
      if (error.response.status == 422) {
        dispatch({
          type: UPDATE_CURRENLLENUSES_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };

export const createCurrenllenusesVisitAction =
  (visit_id, currenllenuses_id) => async (dispatch) => {
    loading();
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    // console.log(types)
    try {
      const resp = await api.post(
        "currentllenussVisit/create",
        {
          visit_id,
          currenllenuses_id,
        },
        config
      );
      const { status, data } = resp;

      if (status == 201) {
        dispatch({
          type: UPDATE_CURRENLLENUSES_VISIT_SUCCESS,
          error: "",
        });
        Swal.fire({
          icon: "success",
          title: "Good Job",
          text: "Your work has been saved",
          timer: 1500,
        });
      }
    } catch (error) {
      // console.log(error);
      if (error.response.status == 422) {
        dispatch({
          type: UPDATE_CURRENLLENUSES_VISIT_FAIL,
          error: error.response.data.errors,
          payload: [],
        });
      }
    }
  };

import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { createDrugAction } from "../../Actions/DrugAction/DrugAction";

const DrugModal = ({ isOpen, onClose }) => {
  const [inputText, setInputText] = useState("");
  const dispatch = useDispatch();
  const handleSave = () => {
    dispatch(createDrugAction(inputText));
    onClose();
  };
  return (
    <>
      {isOpen && (
        <div className="modal">
          <div className="modal-dialog">
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="modal-overlay"></div>
            </div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div
              className="modal-content"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-headline"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-headline"
                    >
                      Drug
                    </h3>
                    <div className="mt-2">
                      <input
                        type="text"
                        value={inputText}
                        onChange={(e) => setInputText(e.target.value)}
                        className="input w-full"
                        placeholder="Drug"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer gap-2">
                <button
                  onClick={handleSave}
                  type="button"
                  className="btn-success"
                >
                  Save
                </button>
                <button
                  onClick={onClose}
                  type="button"
                  className="btn-close rounded-md"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DrugModal;

import React, { useEffect, useRef, useState } from "react";
import { MdOutlineSave } from "react-icons/md";
import ReactQuill from "react-quill";

import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import {
  createReportAction,
  updateReportAction,
} from "../../../../../Actions/ReportAction/ReportAction";
import { showPatientAction } from "../../../../../Actions/PatientActions/PatientAction";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { FaPrint } from "react-icons/fa";
import PrintPage from "../../PrintPage/PrintPage";
const Report = ({ visits }) => {
  const { id: patientId } = useParams();
  const showPatient = useSelector((state) => state.showPatient);
  const { visits: getVisits } = showPatient;
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  useEffect(() => {
    getVisits
      ?.filter((filter) => filter.id == visits.id)
      .map((visit) => {
        
        setValue(visit?.reports?.note);
      });
  }, [getVisits]);
  const handleSubmit = () => {
    let ids = [];
    dispatch(showPatientAction(patientId));
    dispatch(showPatientAction(patientId));

    getVisits
      ?.filter((filter) => filter.id == visits.id)
      .map((visit) => {
        if (visit?.reports?.id) {
          ids.push(visit?.reports?.id);
        }
      });

    if (ids.length > 0) {
      dispatch(updateReportAction(ids, visits.id, value));
      return;
    } else {
      console.log(visits.id, value);
      dispatch(createReportAction(visits.id, value));
    }
  };
  return (
    <div>
      {" "}
      <div hidden>
        <PrintPage
          ref={componentRef}
          visitId={visits.id}
          note={value}
          visits={getVisits}
          type={"report"}
        />
      </div>
      <ReactQuill value={value} onChange={setValue} />
      <div className="flex items-center gap-2">
        <button
          onClick={handleSubmit}
          className="btn-info flex gap-2 items-center mt-2"
        >
          Save <MdOutlineSave />
        </button>
        <button
          onClick={handlePrint}
          className="btn-primary flex gap-4 items-center mt-2"
        >
          Print <FaPrint />
        </button>
      </div>
    </div>
  );
};

export default Report;

import {
  CREATE_VISIT_FAIL,
  CREATE_VISIT_REQUEST,
  CREATE_VISIT_SUCCESS,
  FETCH_TOTAL_FAIL,
  FETCH_TOTAL_REQUEST,
  FETCH_TOTAL_SUCCESS,
  FETCH_VISIT_FAIL,
  FETCH_VISIT_REQUEST,
  FETCH_VISIT_SUCCESS,
  UPDATE_VISIT_FAIL,
  UPDATE_VISIT_REQUEST,
  UPDATE_VISIT_SUCCESS,
} from "../../ActionType/VisitTypes/visitTypes";

export const getVisitReducer = (
  state = { loading: true, visits: [],total:0, error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_VISIT_REQUEST:
      return {
        loading: true,
      };
    case FETCH_VISIT_SUCCESS:
      return {
        loading: false,
        visits: action.payload.data,
        total: action.payload.total,
        error: "",
      };
    case FETCH_VISIT_FAIL:
      return {
        loading: false,
        visits: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};
export const getTotalReducer = (
  state = { loading: true, items: [], error: "" },
  action
) => {
  switch (action.type) {
    case FETCH_TOTAL_REQUEST:
      return {
        loading: true,
      };
    case FETCH_TOTAL_SUCCESS:
      return {
        loading: false,
        items: action.payload,
        
        error: "",
      };
    case FETCH_TOTAL_FAIL:
      return {
        loading: false,
        items: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};
export const createVisitReducer = (
  state = { loading: true, visits: [], errors: "" },
  action
) => {
  // console.log(action.error)
  switch (action.type) {
    case CREATE_VISIT_REQUEST:
      return {
        loading: true,
      };

    case CREATE_VISIT_SUCCESS:
      return {
        loading: false,
        visits: action.payload,
        error: "",
      };
    case CREATE_VISIT_FAIL:
      return {
        loading: false,
        visits: [],
        errors: action.error,
      };
    default:
      return state;
  }
};

export const updateVisitReducer = (
  state = { loading: true, visits: [], error: "" },
  action
) => {
  switch (action.type) {
    case UPDATE_VISIT_REQUEST:
      return {
        loading: true,
      };
    case UPDATE_VISIT_SUCCESS:
      return {
        loading: false,
        visits: action.payload,
        error: "",
      };
    case UPDATE_VISIT_FAIL:
      return {
        loading: false,
        visits: [],
        error: "Check Your Request",
      };
    default:
      return state;
  }
};


export const getToday = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
  const day = today.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getTomorrow  = () => {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const year = tomorrow.getFullYear();
  const month = (tomorrow.getMonth() + 1).toString().padStart(2, '0'); // Month is zero-indexed
  const day = tomorrow.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};